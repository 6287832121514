import React from 'react';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <button
      onClick={handleBack}
      style={{
        padding: "10px 20px",
        marginBottom: "10px",
        fontSize: "16px",
        fontWeight: "bold",
        color: "white",
        backgroundColor: "#007bff",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
      }}
    >
      Back
    </button>
  );
};

export default BackButton;
