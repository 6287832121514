import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import apiConfig from '../../../apiConfig';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';

const EditProducts = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { product } = location.state;

  const [formData, setFormData] = useState({
    title: '',
    brand: '',
    description: '',
    regularPrice: '',
    salePrice: '',
    stock: '',
    productimage1: null,
    productimage2: null,
    productimage3: null
  });

  const [specifications, setSpecifications] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (product) {
      setFormData({
        brand: product.brand,
        title: product.title,
        
        description: product.description,
        regularPrice: product.regularPrice,
        salePrice: product.salePrice,
        stock: product.stock,
        productimage1: product.productimage1,
        productimage2: product.productimage2,
        productimage3: product.productimage3
      });

      // Initialize specifications
      setSpecifications(product.specifications || []);
    }
  }, [product]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'productimage1' || name === 'productimage2' || name === 'productimage3') {
      setFormData({
        ...formData,
        [name]: files[0]
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleSpecificationChange = (index, e) => {
    const { name, value } = e.target;
    const updatedSpecifications = [...specifications];
    updatedSpecifications[index] = {
      ...updatedSpecifications[index],
      [name]: value
    };
    setSpecifications(updatedSpecifications);
  };

  const addSpecification = () => {
    setSpecifications([...specifications, { key: '', values: [''] }]);
  };

  const removeSpecification = (index) => {
    setSpecifications(specifications.filter((_, i) => i !== index));
  };

  const handleSpecificationValueChange = (specIndex, valueIndex, e) => {
    const { value } = e.target;
    const updatedSpecifications = [...specifications];
    updatedSpecifications[specIndex].values[valueIndex] = value;
    setSpecifications(updatedSpecifications);
  };

  const addSpecificationValue = (specIndex) => {
    const updatedSpecifications = [...specifications];
    updatedSpecifications[specIndex].values.push('');
    setSpecifications(updatedSpecifications);
  };

  const removeSpecificationValue = (specIndex, valueIndex) => {
    const updatedSpecifications = [...specifications];
    updatedSpecifications[specIndex].values = updatedSpecifications[specIndex].values.filter((_, i) => i !== valueIndex);
    setSpecifications(updatedSpecifications);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("title", formData.title);
    formDataToSend.append("brand", formData.brand);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("regularPrice", formData.regularPrice);
    formDataToSend.append("salePrice", formData.salePrice);
    formDataToSend.append("stock", formData.stock);
    formDataToSend.append("productimage1", formData.productimage1);
    formDataToSend.append("productimage2", formData.productimage2);
    formDataToSend.append("productimage3", formData.productimage3);
    formDataToSend.append("specifications", JSON.stringify(specifications));

    try {
      const response = await axios.put(`${apiConfig.baseUrl}${apiConfig.updateProduct}${id}`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Form data sent successfully:', response.data);
      setShowModal(true);
    } catch (error) {
      console.error('Error sending form data:', error);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    navigate(`/admin/ViewProduct`);
  };

  const handleCancel = () => {
    navigate(`/admin/ViewProduct`);
  };




  return (
    <div>
      <h2 style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>Edit Product: {product.title}</h2>
      <form onSubmit={handleSubmit} style={{ maxWidth: '600px', margin: 'auto' }}>
        <div className="mb-3">
          <label className="form-label">Titletttt:</label>
          <input type="text" className="form-control" name="title" value={formData.title} onChange={handleInputChange} />
        </div>
        <div className="mb-3">
          <label className="form-label">Brand:</label>
          <input type="text" className="form-control" name="brand" value={formData.brand} onChange={handleInputChange} />
        </div>

        <div className="mb-3">
          <label className="form-label">Description:</label>
          <textarea className="form-control" name="description" value={formData.description} onChange={handleInputChange} rows="10" cols="90" />
        </div>

        <div className="mb-3">
          <label className="form-label">Regular Price:</label>
          <input type="text" className="form-control" name="regularPrice" value={formData.regularPrice} onChange={handleInputChange} />
        </div>

        <div className="mb-3">
          <label className="form-label">Sale Price:</label>
          <input type="text" className="form-control" name="salePrice" value={formData.salePrice} onChange={handleInputChange} />
        </div>

        <div className="mb-3">
          <label className="form-label">Stock:</label>
          <input type="text" className="form-control" name="stock" value={formData.stock} onChange={handleInputChange} />
        </div>

        <div className="mb-3">
          <label className="form-label">Product Image 1:</label>
          <input type="file" className="form-control" name="productimage1" onChange={handleInputChange} accept="image/*" />
          {formData.productimage1 && (
            <img src={URL.createObjectURL(formData.productimage1)} alt="Product Image 1 Preview" style={{ width: '100px', height: 'auto', marginTop: '10px' }} />
          )}
        </div>

        <div className="mb-3">
          <label className="form-label">Product Image 2:</label>
          <input type="file" className="form-control" name="productimage2" onChange={handleInputChange} accept="image/*" />
          {formData.productimage2 && (
            <img src={URL.createObjectURL(formData.productimage2)} alt="Product Image 2 Preview" style={{ width: '100px', height: 'auto', marginTop: '10px' }} />
          )}
        </div>

        <div className="mb-3">
          <label className="form-label">Product Image 3:</label>
          <input type="file" className="form-control" name="productimage3" onChange={handleInputChange} accept="image/*" />
          {formData.productimage3 && (
            <img src={URL.createObjectURL(formData.productimage3)} alt="Product Image 3 Preview" style={{ width: '100px', height: 'auto', marginTop: '10px' }} />
          )}
        </div>

        <div className="mb-3">
          <label className="form-label">Specifications:</label>
          {specifications.map((spec, specIndex) => (
            <div key={specIndex} className="mb-3">
              <label className="form-label">Key:</label>
              <input
                type="text"
                className="form-control"
                name="key"
                value={spec.key}
                onChange={(e) => handleSpecificationChange(specIndex, e)}
              />
              <label className="form-label">Values:</label>
              {spec.values.map((value, valueIndex) => (
                <div key={valueIndex} className="mb-2">
                  <input
                    type="text"
                    className="form-control"
                    value={value}
                    onChange={(e) => handleSpecificationValueChange(specIndex, valueIndex, e)}
                  />
                  <button
                    type="button"
                    className="btn btn-danger btn-sm mt-2"
                    onClick={() => removeSpecificationValue(specIndex, valueIndex)}
                  >
                    Remove Value
                  </button>
                </div>
              ))}
              <button
                type="button"
                className="btn btn-secondary mt-2"
                onClick={() => addSpecificationValue(specIndex)}
              >
                Add Value
              </button>
              <button
                type="button"
                className="btn btn-danger btn-sm mt-2"
                onClick={() => removeSpecification(specIndex)}
              >
                Remove Specification
              </button>
            </div>
          ))}
          <button
            type="button"
            className="btn btn-primary mt-2"
            onClick={addSpecification}
          >
            Add Specification
          </button>
        </div>

        <button type="submit" className="btn btn-primary">Save Changes</button>
        <button type="button" style={{ marginLeft: 20 }} className="btn btn-danger" onClick={handleCancel}>Cancel</button>
      </form>

      {/* Modal to show updated data */}
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Product Updated</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Product <strong>{formData.title}</strong> has been successfully updated.</p>
          {/* Display other updated information as needed */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditProducts;
