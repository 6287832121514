import React, { useState, useEffect } from "react";
import axios from "axios";
import apiConfig from "../../../apiConfig";

const OrderStatus = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedOrder, setSelectedOrder] = useState("");
  const [newStatus, setNewStatus] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [newPaymentStatus, setNewPaymentStatus] = useState("");
  const [newPaymentMode, setNewPaymentMode] = useState("");

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${apiConfig.baseUrl}/admin/getAllOrders`
      );
      const reversedOrders = response.data.reverse();
      setOrders(reversedOrders);
      setFilteredOrders(reversedOrders);
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false);
    }
  };

  const getNextStatus = (currentStatus) => {
    switch (currentStatus) {
      case "received":
        return "processing";
      case "processing":
        return "shipped";
      case "shipped":
        return "delivered";
      case "delivered":
        return "";
      case "cancelled":
        return "";
      default:
        return "";
    }
  };

  const handleStatusChange = async (orderID, paymentStatus) => {
    setLoading(true);
    try {
      const nextStatus = getNextStatus(selectedStatus);
  
      if (nextStatus === newStatus) {
        if (newStatus === "delivered" && paymentStatus !== "Paid") {
          setModalMessage("Cannot update to 'delivered' status. The payment status is not 'paid'.");
          setShowModal(true);
        } else {
          await axios.put(
            `${apiConfig.baseUrl}/admin/order/${orderID}/updateStatus`,
            { status: newStatus }
          );
  
          await fetchOrders();
          setModalMessage("Order status updated successfully.");
          setShowModal(true);
        }
      } else {
        console.error("Cannot update to the selected status:", newStatus);
      }
    } catch (err) {
      console.error("Error updating order status:", err);
    } finally {
      setLoading(false);
    }
  };
  



  const getStatusColor = (status) => {
    switch (status) {
      case "received":
        return "blue";
      case "processing":
        return "#EF9B0F";
      case "shipped":
        return "green";
      case "delivered":
        return "purple";
      case "cancelled":
        return "#C40233";
      default:
        return "black";
    }
  };

  const handleFilter = (status) => {
    setSelectedStatus(status);
    if (status === "") {
      setFilteredOrders(orders.reverse());
    } else {
      const filtered = orders.filter((order) => order.status === status);
      setFilteredOrders(filtered.reverse());
    }
  };

  const handlePaymentStatusChange = async (orderID) => {
    setLoading(true);
    try {
      await axios.put(`${apiConfig.baseUrl}/admin/update/paymentstatus`, {
        orderId: orderID,
        paymentStatus: newPaymentStatus,
        paymentMode: newPaymentMode
      });
      await fetchOrders();
      setModalMessage("Payment status updated successfully.");
      setShowModal(true);
    } catch (err) {
      console.error("Error updating payment status:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="order-detail">
      <h1>Order Status</h1>
      <div className="filter-buttons">
        <button className="filter-btn" onClick={() => handleFilter("received")}>
        Received
        </button>
        <button
          className="filter-btn"
          onClick={() => handleFilter("processing")}
        >
          Processing
        </button>
        <button className="filter-btn" onClick={() => handleFilter("shipped")}>
          Shipped
        </button>
        <button
          className="filter-btn"
          onClick={() => handleFilter("delivered")}
        >
          Delivered
        </button>
        <button
          className="filter-btn"
          onClick={() => handleFilter("cancelled")}
        >
          Cancelled
        </button>
        <button className="filter-btn" onClick={() => handleFilter("")}>
          Show All
        </button>
      </div>

      {loading ? (
        <div className="spinner"></div>
      ) : (
        <table>
          <thead>
            <tr>
              <th>User ID</th>
              <th>Order ID</th>
              <th>Status</th>
              <th>Total Price</th>
         
              <th>Change Status</th>
              <th>Payment Status</th>
              <th>Payment Mode</th>
              <th>Change status/mode</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrders.length === 0 ? (
              <tr>
                <td colSpan="7" style={{ textAlign: "center" }}>
                  No orders available
                </td>
              </tr>
            ) : (
              filteredOrders.map((order) => (
                <tr key={order.orderID}>
                  <td>{order.userId}</td>
                  <td>{order.orderID}</td>
                  <td style={{ color: getStatusColor(order.status) }}>
                    {order.status}
                  </td>
                  <td>₹{order.totalPrice}</td>
                 
                  <td>
                    {order.status !== "delivered" && (
                      <>
                        <select
                          value={newStatus}
                          onChange={(e) => setNewStatus(e.target.value)}
                        >
                          <option value="">Select Status</option>
                          <option value={getNextStatus(order.status)}>
                            {getNextStatus(order.status)}
                          </option>
                        </select>
                        <button
                          onClick={() => {
                           
                            setSelectedOrder(order.orderID);
                            setSelectedStatus(order.status);
                            handleStatusChange(order.orderID, order.paymentStatus);
                          }}
                        >
                          Update Status
                        </button>
                      </>
                    )}
                  </td>
                  <td>{order.paymentStatus}</td>
                  <td>{order.paymentMode}</td>
                  <td>
                    {order.status === "shipped" && (
                      <>
                        <select
                          value={newPaymentMode}
                          onChange={(e) => setNewPaymentMode(e.target.value)}
                        >
                          <option value="">Select Payment Mode</option>
                          <option value="Online">Online</option>
                          <option value="Cash">Cash</option>
                        </select>
                        <select
                          value={newPaymentStatus}
                          onChange={(e) => setNewPaymentStatus(e.target.value)}
                        >
                          <option value="">Select Payment Status</option>
                          <option value="Paid">Paid</option>
                          <option value="Pending">pending</option>
                        </select>
                        <button
                          onClick={() => handlePaymentStatusChange(order.orderID)}
                        >
                          Update Payment
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      )}
      {showModal && (
        <div className="modal ">
          <p style={{ color: "green" }}>{modalMessage}</p>
          <button onClick={() => setShowModal(false)}>Close</button>
        </div>
      )}
    </div>
  );
};

// Inline CSS
const style = `
.order-detail {
  font-family: Arial, sans-serif;
  margin: 20px;
}

h1 {
  text-align: center;
}

.filter-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.filter-btn {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.filter-btn:hover {
  background-color: #0056b3;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

th {
  background-color: #f4f4f4;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.modal {

position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: white;
border: 1px solid #ccc;
padding: 20px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
z-index: 1000;
}

.modal button {
  margin-left: 10px;
  margin-top:-10px;
   background-color: red;
}
`;

// Create a style element and append it to the document head
const styleElement = document.createElement("style");
styleElement.innerHTML = style;
document.head.appendChild(styleElement);

export default OrderStatus;


{/* <th>Created At</th>
<th>Updated At</th>
 <td>{new Date(order.createdAt).toLocaleString()}</td>
 <td>{new Date(order.updatedAt).toLocaleString()}</td> */}