import React, { useState } from "react";
import axios from "axios";
import apiConfig from "../../../apiConfig";
import CurrentAppVersion from "./CurrentAppVersion";

const UpdateVersion = () => {
  const [version, setVersion] = useState("");
  const [successMode, setSuccessMode] = useState(false);
  const [error, setError] = useState("");

  const updateVersion = async () => {
    try {
      const versionRegex = /^\d+(\.\d+){1}$/;
      if (!versionRegex.test(version)) {
        setError("Version must be in format 0.0");
        return;
      }

      const response = await axios.post(
        `${apiConfig.baseUrl}${apiConfig.AddAppNewVerion}`,
        {
          version: version,
        }
      );

      setSuccessMode(true);
      setVersion("");
      setError("");
    } catch (error) {
      console.error("Failed to update version:", error);
    }
  };

  const resetForm = () => {
    setVersion("");
    setSuccessMode(false);
    setError("");
  };

  return (
    <div style={styles.container}>
      <div style={styles.left}>
        <CurrentAppVersion />
      </div>
      <div style={styles.right}>
        {!successMode ? (
          <>
            <input
              type="text"
              value={version}
              onChange={(e) => setVersion(e.target.value)}
              placeholder="Enter version (e.g., 0.0)"
              style={styles.input}
            />
            {error && <p style={styles.error}>{error}</p>}
            <button onClick={updateVersion} style={styles.button}>
              Update Version
            </button>
          </>
        ) : (
          <div style={styles.successMessage}>
            <p>Version {version} successfully updated!</p>
            <button onClick={resetForm} style={styles.resetButton}>
              Update Another Version
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "flex-start",
    height: "100vh",
    width: "100%",
    padding: "20px",
    position: "fixed",
  },
  left: {
    width: "30%",
    marginRight: "20px",
  },
  right: {
    width: "60%",
    padding: "20px",
    backgroundColor: "#f8f9fa",
    borderRadius: "4px",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
  },
  input: {
    padding: "10px",
    fontSize: "16px",
    marginBottom: "10px",
    width: "100%",
    maxWidth: "300px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    boxSizing: "border-box",
    marginTop: "5%",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginTop: "10px",
  },
  successMessage: {
    textAlign: "center",
    padding: "20px",
    backgroundColor: "#dff0d8",
    border: "1px solid #c3e6cb",
    borderRadius: "4px",
    marginBottom: "20px",
  },
  resetButton: {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#28a745",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginTop: "10px",
  },
  error: {
    color: "red",
    marginBottom: "10px",
  },
};

export default UpdateVersion;
