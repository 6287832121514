import React, { useState, useEffect } from "react";
import axios from "axios";
import apiConfig from "../../../apiConfig";


const ViewTrendingProducts = () => {
    const [trendingProducts, setTrendingProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    console.log(trendingProducts);
  
    const fetchTrendingProducts = async () => {
        try {
            const response = await axios.get(`${apiConfig.baseUrl}user/getTrendingProduct`);
            console.log("response-----", response);
            console.log("response data-----", response.data);
    
            // Extract trendingProducts from the response data  
            const data = response.data.trendingProducts;
            
            if (Array.isArray(data)) {
                setTrendingProducts(data);
            } else {
                throw new Error("Unexpected data format");
            }
        } catch (error) {
            console.error("Error fetching trending products:", error);
        } finally {
            setLoading(false);
        }
    };
  
    const handleRemoveTrendingProduct = async (productId) => {
        console.log("productId",productId);
        
        try {
            console.log(`Removing product with ID: ${productId}`);
            const response = await axios.put(`${apiConfig.baseUrl}admin/remove/trendingtag/${productId}`);
           
            if (response.status === 200) {
                console.log("Product removed successfully");
                setTrendingProducts((prevProducts) => 
                    prevProducts.filter((p) => p._id !== productId)
                );
                alert("Product removed from trending list");
            } else {
                throw new Error("Failed to remove product from trending");
            }
        } catch (error) {
            console.error("Error removing product from trending:", error);
            alert("An error occurred while removing the product");
        }
    };
  
    useEffect(() => {
      fetchTrendingProducts();
    }, []);

  return (
    <div>
      <h1>Trending Products</h1>
       {loading ? (
        <p>Loading...</p>
      ) : (
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th style={{ border: "1px solid black", padding: "8px" }}>ID</th>
              <th style={{ border: "1px solid black", padding: "8px" }}>Title</th>
             <th style={{ border: "1px solid black", padding: "4px" ,textAlign:"center"}}>Category</th>
              <th style={{ border: "1px solid black", padding: "8px" ,textAlign:"center"}}>Remove Tranding Product</th>
              
            </tr>
          </thead>
          <tbody>
            {trendingProducts.map((product) => (
              <tr key={product._id}>
                <td style={{ border: "1px solid black", padding: "8px" }}>{product._id}</td>
                <td style={{ border: "1px solid black", padding: "8px" }}>{product.title || "No Title"}</td>
                <td style={{ border: "1px solid black", padding: "8px" }}>{product.category || "N/A"}</td>
                <td style={{ border: "1px solid black", padding: "8px" }}>
                  <button
                    onClick={() => handleRemoveTrendingProduct(product._id)}
                    style={{
                      backgroundColor: 'red',
                      color: 'white',
                      padding: '5px 10px',
                      border: 'none',
                      cursor: 'pointer',
                    }}
                  > Remove 
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )} 
    </div>
  );
};

export default ViewTrendingProducts;
