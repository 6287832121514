
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'bootstrap/dist/js/bootstrap.bundle';

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light shadow sticky-top ">
      <div className="container">
        <Link className="navbar-brand" to="/">Navbar</Link>
        <button 
          className="navbar-toggler" 
          type="button" 
          data-bs-toggle="collapse" 
          data-bs-target="#navbarSupportedContent" 
          aria-controls="navbarSupportedContent" 
          aria-expanded="false" 
          aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link active" aria-current="page" to="/" refresh="true">Home</Link>
            </li>
          
            <li className="nav-item">
              <Link className="nav-link" to="/Login" refresh="true">Login</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Register" refresh="true" >Register</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/ResetPassword" refresh="true">ResetPassword</Link>
            </li>
           
          </ul>
         
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
