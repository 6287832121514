import React, { useEffect, useState } from "react";
import axios from "axios";
import apiConfig from "../../apiConfig";

const Profile = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${apiConfig.baseUrl}${apiConfig.getAllUsers}`);
        setUsers(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleDeleteUser = async (userId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this user?");
    if (confirmDelete) {
      try {
        await axios.delete(`${apiConfig.baseUrl}${apiConfig.deleteUser}${userId}`);
        // If successful, remove the deleted user from the state
        setUsers(users.filter(user => user._id !== userId));
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!users.length) {
    return <p>No user data available</p>;
  }

  return (
    <div>
      <h1>Users</h1>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Role</th>
            <th>Address</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={index}>
              <td>{user._id}</td>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.phoneNo}</td>
              <td>{user.role}</td>
              <td>
                {user.addresses && user.addresses.length > 0 ? (
                  user.addresses.map((address, addressIndex) => (
                    <div key={addressIndex}>
                      {address.location}, {address.city}, {address.state}, {address.zip} ({address.type})
                    </div>
                  ))
                ) : (
                  <div>No addresses found</div>
                )}
              </td>
              <td>
                <button  className="btn btn-sm btn-danger" onClick={() => handleDeleteUser(user._id)}>Delete User</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Profile;
