import React, { useState } from 'react';
import axios from 'axios';
import apiConfig from "../../../apiConfig";

const AddSpecifications = () => {
  const [keys, setKeys] = useState(['']); 

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
   
      for (let key of keys) {
        if (key.trim()) {
          const response = await axios.post(`${apiConfig.baseUrl}${apiConfig.AddSpecifications}`, { key });
          console.log('Response data:', response.data); 
          if (response.status === 201) {
            alert('Specification added successfully!');
          }
        }
      }

    } catch (error) {
      
      if (error.response) {
    
        console.error('Server responded with a status:', error.response.status);
        console.error('Response data:', error.response.data);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error setting up the request:', error.message);
      }
      alert('Failed to add specifications. Please try again.');
    }
  };

  const handleKeyChange = (index, event) => {
    const newKeys = [...keys];
    newKeys[index] = event.target.value;
    setKeys(newKeys);
  };

  // const addKey = () => {
  //   setKeys([...keys, '']);
  // };

  const removeKey = (index) => {
    const newKeys = keys.filter((_, i) => i !== index);
    setKeys(newKeys);
  };

  return (
    <div style={{
      margin: '40px auto',
      maxWidth: '600px',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#f9f9f9',
      fontFamily: 'Arial, sans-serif'
    }}>
      <h2 style={{ color: '#333', marginBottom: '20px' }}>Add Specification Keys</h2>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
        {keys.map((key, index) => (
          <div key={index} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
            <input
              type="text"
              placeholder="Specification Key"
              value={key}
              onChange={(e) => handleKeyChange(index, e)}
              style={{
                padding: '8px',
                border: '1px solid #ddd',
                borderRadius: '4px',
                fontSize: '16px',
                flex: '1'
              }}
            />
            <button
              type="button"
              onClick={() => removeKey(index)}
              style={{
                marginLeft: '10px',
                padding: '8px 12px',
                backgroundColor: '#dc3545',
                color: '#fff',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            >
              Remove
            </button>
          </div>
        ))}

        {/* <button
          type="button"
          onClick={addKey}
          style={{
            padding: '10px 20px',
            backgroundColor: '#28a745',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '16px',
            marginBottom: '20px',
            alignSelf: 'flex-start'
          }}
          onMouseOver={(e) => e.target.style.backgroundColor = '#218838'}
          onMouseOut={(e) => e.target.style.backgroundColor = '#28a745'}
        >
          Add Specification Key
        </button> */}

        <button
          type="submit"
          style={{
            padding: '12px 20px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '16px',
            alignSelf: 'flex-start'
          }}
          onMouseOver={(e) => e.target.style.backgroundColor = '#0056b3'}
          onMouseOut={(e) => e.target.style.backgroundColor = '#007bff'}
        >
          Submit Specifications
        </button>
      </form>
    </div>
  );
};

export default AddSpecifications;
