import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const DropdownOfStatus = () => {
  const [status, setStatus] = useState("");
  const navigate = useNavigate();

  const handleChange = (event) => {
    const selectedStatus = event.target.value;
    console.log("Selected Status:", selectedStatus);

    setStatus(selectedStatus);

    if (selectedStatus) {
      console.log("Navigating to:", `/admin/${selectedStatus}`);
      navigate(`/admin/${selectedStatus}`);
    }
  };



  return (
    <>
    <div
      style={{
        padding: "10px 20px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        backgroundColor: "#f9f9f9",
        marginLeft: "0", 
      }}
    >
  

      <label
        htmlFor="status"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "bold",
  
        }}
      >
        Order Status:
      </label>
      <select
        id="status"
        value={status}
        onChange={handleChange}
        style={{
          width: "100%",
          padding: "10px",
          fontSize: "16px",
          borderRadius: "5px",
          border: "1px solid #ccc",
        }}
      >
        <option value="">Select Status</option>
        <option value="Delivered">Delivered Orders</option>
        <option value="Accepted">Accepted Orders</option>
        <option value="NotAccepted">Not Accepted Orders</option>
        <option value="Shipped">Shipped Orders</option>
        <option value="Processing">Processing Orders</option>
        <option value="Cancelled">Cancelled Orders</option>
      </select>
    </div>
   
    </>
  );
};

export default DropdownOfStatus;
