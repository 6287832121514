// import React, { useState } from 'react';
// import axios from 'axios';
// import apiConfig from "../../../apiConfig";

// const AddBrand = () => {
//   const [brandName, setBrandName] = useState('');

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post( `${apiConfig.baseUrl}${apiConfig.AddBrand}`, { name: brandName });

//       // Log the response data in the console
//       console.log('Response data:', response.data);

//       // Show an alert with the response message
//       if (response.status === 200) {
//         alert('Brand added successfully!');
//         setBrandName(''); // Clear the input field
//       }
//     } catch (error) {
//       // Log the error message in the console
//       console.error('Error adding brand:', error);

//       // Show an alert with the error message
//       alert('Failed to add brand. Please try again.');
//     }
//   };

//   return (
//     <div style={{
//       margin: '40px auto',
//       maxWidth: '600px',
//       padding: '20px',
//       borderRadius: '8px',
//       boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//       backgroundColor: '#f9f9f9',
//       fontFamily: 'Arial, sans-serif'
//     }}>
//       <h2 style={{ color: '#333', marginBottom: '20px' }}>Add New Brand</h2>
//       <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
//         <div style={{ marginBottom: '15px' }}>
//           <label htmlFor="brandName" style={{ fontSize: '16px', marginBottom: '5px', display: 'block' }}>
//             Brand Name:
//           </label>
//           <input
//             type="text"
//             id="brandName"
//             value={brandName}
//             onChange={(e) => setBrandName(e.target.value)}
//             required
//             style={{
//               padding: '10px',
//               border: '1px solid #ddd',
//               borderRadius: '4px',
//               fontSize: '16px',
//               width: '100%',
//               boxSizing: 'border-box',
//               outline: 'none',
//               transition: 'border-color 0.3s ease'
//             }}
//           />
//         </div>
//         <button
//           type="submit"
//           style={{
//             padding: '12px 20px',
//             backgroundColor: '#007bff',
//             color: '#fff',
//             border: 'none',
//             borderRadius: '4px',
//             cursor: 'pointer',
//             fontSize: '16px',
//             transition: 'background-color 0.3s ease',
//             alignSelf: 'flex-start'
//           }}
//           onMouseOver={(e) => e.target.style.backgroundColor = '#0056b3'}
//           onMouseOut={(e) => e.target.style.backgroundColor = '#007bff'}
//         >
//           Add Brand
//         </button>
//       </form>
//     </div>
//   );
// };

// export default AddBrand;

import React, { useState } from "react";
import axios from "axios";
import apiConfig from "../../../apiConfig";

const AddBrand = () => {
  const [brandName, setBrandName] = useState("");
  const [brandImage, setBrandImage] = useState("");

  console.log("images", brandName, brandImage);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", brandName);
    if (brandImage) {
      formData.append("images", brandImage);
    }
    try {
      const response = await axios.post(
        `${apiConfig.baseUrl}${apiConfig.AddBrand}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        alert("Brand added successfully!");
        setBrandName("");
        setBrandImage(null);
      }
    } catch (error) {
      console.error("Error adding brand:", error);
      alert("Failed to add brand. Please try again.");
    }
  };

  return (
    <div
      style={{
        margin: "40px auto",
        maxWidth: "600px",
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#f9f9f9",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <h2 style={{ color: "#333", marginBottom: "20px" }}>Add New Brand</h2>
      <form
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div style={{ marginBottom: "15px" }}>
          <label
            htmlFor="brandName"
            style={{ fontSize: "16px", marginBottom: "5px", display: "block" }}
          >
            Brand Name:
          </label>
          <input
            type="text"
            id="brandName"
            value={brandName}
            onChange={(e) => setBrandName(e.target.value)}
            required
            style={{
              padding: "10px",
              border: "1px solid #ddd",
              borderRadius: "4px",
              fontSize: "16px",
              width: "100%",
              boxSizing: "border-box",
              outline: "none",
              transition: "border-color 0.3s ease",
            }}
          />
        </div>
        <div style={{ marginBottom: "15px" }}>
          <label
            htmlFor="brandImage"
            style={{ fontSize: "16px", marginBottom: "5px", display: "block" }}
          >
            Brand Image:
          </label>
          <input
            type="file"
            id="brandImage"
            accept="image/*"
            onChange={(e) => setBrandImage(e.target.files[0])}
            style={{
              padding: "10px",
              border: "1px solid #ddd",
              borderRadius: "4px",
              fontSize: "16px",
              width: "100%",
              boxSizing: "border-box",
              outline: "none",
              transition: "border-color 0.3s ease",
            }}
          />
        </div>
        <button
          type="submit"
          style={{
            padding: "12px 20px",
            backgroundColor: "#007bff",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            fontSize: "16px",
            transition: "background-color 0.3s ease",
            alignSelf: "flex-start",
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = "#0056b3")}
          onMouseOut={(e) => (e.target.style.backgroundColor = "#007bff")}
        >
          Add Brand
        </button>
      </form>
    </div>
  );
};

export default AddBrand;
