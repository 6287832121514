import React, { useState } from 'react';
import axios from 'axios';
import ViewPromotionalImg from './ViewPromotionalImg';
import apiConfig from '../../../apiConfig';
import './AddPromotionalImg.css'; 

const AddPromotionalImg = () => {
  const [loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFileNames, setSelectedFileNames] = useState([]); 
  const [modalOpen, setModalOpen] = useState(false);
  const [uploadResponse, setUploadResponse] = useState(null);
  const [error, setError] = useState(null);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
    const fileNames = files.map(file => file.name); 
    setSelectedFileNames(fileNames); 
  };

  const handleFileUpload = async () => {
    if (selectedFiles.length === 0) {
      alert('Please select one or more files.');
      return;
    }

    setLoading(true);

    try {
      const promises = selectedFiles.map(async (file) => {
        const formData = new FormData();
        formData.append('images', file);

        const response = await axios.post(
          `${apiConfig.baseUrl}${apiConfig.UploadPromotionalOfferImg}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        );
        return response.data;
      });

      const results = await Promise.all(promises);
      console.log('Images uploaded successfully:', results);

      setUploadResponse(results);
      setModalOpen(true);
      setError(null);

    } catch (error) {
      console.error('Error uploading images:', error);
      setError('Error uploading images. Please try again.');
    } finally {
      setLoading(false);
      setSelectedFiles([]);
      setSelectedFileNames([]);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setUploadResponse(null);
  };

  return (
    <div className="container">
      <h2>Add Promotional Images</h2>
      <div className='containertwo'>
      <input
        type="file"
        id="fileInput"
        style={{ display: 'none' }}
        onChange={handleFileChange}
        multiple
      />
    
      <button className="label" onClick={() => document.getElementById('fileInput').click()}>
        Choose Files
      </button>

      {/* Display selected file names */}
      <div className="selected-files">
        {selectedFileNames.map((fileName, index) => (
          <p key={index}>{fileName}</p>
        ))}
      </div>

      <button className="button" onClick={handleFileUpload}>
        Upload Images
      </button>

      {loading && <p className="loading-message">Loading...</p>}
      {error && <p className="error-message">{error}</p>}

      {modalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <h3>Upload Response</h3>
            <pre>
              {uploadResponse.map((response, index) => (
                <div key={index}>
                  <p>{response.message}</p>
                </div>
              ))}
            </pre>
          </div>
        </div>
      )}
      </div>
      <ViewPromotionalImg />
    </div>
  );
};

export default AddPromotionalImg;
