import React, { useState } from 'react';
import axios from 'axios';
import './AddNotification.css';
import apiConfig from '../../../apiConfig';

const AddNotification = () => {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [notificationResponse, setNotificationResponse] = useState('');
  const [showModal, setShowModal] = useState(false);

  const sendNotification = () => {
    axios.post(`${apiConfig.baseUrl}${apiConfig.SendNotification}`, {
      title,
      body
    })
    .then(response => {
      setNotificationResponse(response.data);
      setShowModal(true); 
      
      setTitle('');
      setBody('');
    })
    .catch(error => {
      console.error('Error sending notification:', error);
    });
  };

  const handleSendNotification = () => {
    sendNotification();
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="notification-container">
      <div className="notification">
        <div className="notification-header">
          <h2>Add Notification</h2>
        </div>
        <div className="notification-body">
          <div className="form-group">
            <label>Title:</label>
            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
          </div>
          <div className="form-group">
            <label>Body:</label>
            <textarea value={body} onChange={(e) => setBody(e.target.value)} />
          </div>
          <button onClick={handleSendNotification}>Send Notification</button>
          {showModal && (
            <div className="modal">
              <div className="modal-content">
                <span className="close" onClick={closeModal}>&times;</span>
                <h3>Notification Sent Successfully!</h3>
                <p>Response: {JSON.stringify(notificationResponse)}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddNotification;
