import React, { useState, useEffect } from 'react';
import axios from 'axios';
import apiConfig from '../../../apiConfig';

const AddSubCategory = () => {
  const [categories, setCategories] = useState([]);
  const [subcategory, setSubcategory] = useState('');

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${apiConfig.baseUrl}${apiConfig.getCategory}`);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };
  
// http://89.116.33.150:9000/admin/addSubcategory
  const addSubcategory = async (event) => {
    event.preventDefault();
    try {
      const selectedCategoryId = document.getElementById('category').value;
      const selectedCategory = categories.find(cat => cat._id === selectedCategoryId);
      if (!selectedCategory) {
        console.error('Selected category not found');
        return;
      }
  
      const requestBody = {
        name: subcategory,
        parentId: selectedCategoryId
      };
  
      const response = await axios.post(`${apiConfig.baseUrl}${apiConfig.AddSubCategory}`, requestBody);
      console.log('Subcategory added:', response.data);
      setSubcategory('');
    } catch (error) {
      console.error('Error adding subcategory:', error);
    }
  };
  
  

  return (
    <div>
      <h1>Add Subcategory</h1>
      <form onSubmit={addSubcategory}>
        <div className="form-group">
          <label htmlFor="subcategory">Subcategory Name:</label>
          <input
            type="text"
            id="subcategory"
            className="form-control"
            value={subcategory}
            onChange={(e) => setSubcategory(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="category">Select Category:</label>
          <select id="category" className="form-control">
            {categories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
        <button type="submit" className="btn btn-primary">Add Subcategory</button>
      </form>
    </div>
  );
};

export default AddSubCategory;
