import React, { useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import apiConfig from '../../../apiConfig';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
const EditCategory = () => {
  const token = localStorage.getItem('authToken');
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { category } = location.state;

  // Initialize state with category name, status, and images
  const [formData, setFormData] = useState({
    name: category.name,
    status: category.status,
    images: null, 
  });

  // Update state on input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Update state on image selection
  const handleImageChange = (e) => {
    setFormData({
      ...formData,
      images: e.target.files[0] 
    });
  };

  // Handle form submission
  const handleUpdate = async (event) => {
    event.preventDefault();

    // Create FormData object and append form data
    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('images', formData.images);

    console.log(formDataToSend,'ccccccccc')
    try {
      // Send PUT request to update category
      const response = await axios.put(
        `${apiConfig.baseUrl}${apiConfig.updateCategory}/${id}`,
        formDataToSend,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
          }
        }
      );
      console.log("Category updated:", response.data);
      // Redirect to category view page after successful update
      setShowModal(true);
    } catch (error) {
      console.error("Error updating category:", error);
      // Handle error (e.g., show error message to the user)
    }
  };

  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    navigate(`/admin/ViewCategory`);
  };

  const handleCancel = () => {
    navigate(`/admin/ViewCategory`);
  };

  return (
    <div>
      <h2>Edit Category</h2>
      <form onSubmit={handleUpdate} style={styles.form}>
        <div style={styles.formGroup}>
          <label style={styles.label}>Name:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            style={styles.input}
            required 
          />
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label}>Status:</label>
          <select
            name="status"
            value={formData.status}
            onChange={handleInputChange}
            style={styles.select}
           
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label}>Image:</label>
          <input
            type="file"
            name="images"
            onChange={handleImageChange}
            style={styles.input}
            accept="image/*" // Example: accept only image files
          />
        </div>
        <button type="submit" style={styles.button}>Update Category</button>
        <button type="button" style={{marginLeft:20}} className="btn btn-danger" onClick={handleCancel}>Cancel</button>
      </form>

      {/* Modal to show updated data */}
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Product Updated</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Product <strong>{formData.name}</strong> has been successfully updated.</p>
          {/* Display other updated information as needed */}
        </Modal.Body>
        <Modal.Footer>
          <Button  variant="secondary" onClick={closeModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};



  const styles = {
    form: {
      maxWidth: '600px',
      margin: '0 auto',
      padding: '20px',
      border: '1px solid #ccc',
      borderRadius: '8px',
      background: '#f9f9f9',
    },
    formGroup: {
      marginBottom: '15px',
    },
    label: {
      display: 'block',
      marginBottom: '5px',
      fontWeight: 'bold',
    },
    input: {
      width: '100%',
      padding: '8px',
      fontSize: '1rem',
      border: '1px solid #ccc',
      borderRadius: '4px',
      boxSizing: 'border-box',
    },
    select: {
      width: '100%',
      padding: '8px',
      fontSize: '1rem',
      border: '1px solid #ccc',
      borderRadius: '4px',
      boxSizing: 'border-box',
      background: '#fff',
      cursor: 'pointer',
    },
    button: {
      background: '#007bff',
      color: '#fff',
      border: 'none',
      padding: '10px 20px',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '1rem',
    },
  };

export default EditCategory;
