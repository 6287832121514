import React from "react";
import { Link, useNavigate } from "react-router-dom";

function Sidebar() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("authToken");

    navigate("/");
  };

  return (
    <div id="layoutSidenav">
      <div id="layoutSidenav_nav">
        <nav
          className="sb-sidenav accordion sb-sidenav-dark"
          id="sidenavAccordion"
        >
          <div className="sb-sidenav-menu">
            <div className="nav">
              <div className="sb-sidenav-menu-heading">Core</div>
              <Link className="nav-link" to="/admin/dashboard">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                Order Management
              </Link>
              <Link className="nav-link" to="/admin/DropdownOfStatus">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                 Order Status View
              </Link>
               
              <Link className="nav-link" to="/admin/ShippingLabel">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                 Shipping Label
              </Link>

              <Link className="nav-link" to="/admin/add-Category">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                Add Category
              </Link>
              <Link class="nav-link" to="/admin/AddSubCategory">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                AddSubCategory
              </Link>
              <Link class="nav-link" to="/admin/AddBrand">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                AddBrand
              </Link>
             
              <Link className="nav-link" to="/admin/ViewSubcategory">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                ViewSubcategory
              </Link>
              <Link className="nav-link" to="/admin/ViewCategory">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                View Category
              </Link>
              <Link class="nav-link" to="/admin/GetAllBrands">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                ViewBrand
              </Link>
              <Link class="nav-link" to="/admin/AddSpecifications">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                AddSpecification
              </Link>
              <Link class="nav-link" to="/admin/GetAllspecificationkeys">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                ViewSpecification
              </Link>


              <Link className="nav-link" to="/admin/Profile">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                Users
              </Link>

              <Link
                className="nav-link collapsed"
                to="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapseProduct"
                aria-expanded="false"
                aria-controls="collapseProduct"
              >
                <div className="sb-nav-link-icon">
                  <i className="fas fa-columns"></i>
                </div>
                Product
                <div className="sb-sidenav-collapse-arrow">
                  <i className="fas fa-angle-down"></i>
                </div>
              </Link>
              <div
                className="collapse"
                id="collapseProduct"
                aria-labelledby="headingOne"
                data-bs-parent="#sidenavAccordion"
              >
                <nav className="sb-sidenav-menu-nested nav">
                  <Link className="nav-link" to="/admin/AddProduct">
                    {" "}
                    Add Product
                  </Link>
                  <Link className="nav-link" to="/admin/ViewProduct">
                    ViewProduct
                  </Link>
                  <Link
                    className="nav-link"
                    to="/admin/CreatePromotionalOffers"
                  >
                    Add PromotionalOffers
                  </Link>
                  <Link className="nav-link" to="/admin/ViewPromotionalOffers">
                    View PromotionalOffers
                  </Link>
                  <Link className="nav-link" to="/admin/AddPromotionalImg">
                     AddPromotionalImages
                  </Link>

                  <Link className="nav-link" to="/admin/ViewTrandingProduct">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                ViewTrandingProduct
              </Link>
                </nav>
              </div>

              <Link className="nav-link" to="/admin/AddCoupon">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                Add Coupon
              </Link>

              <Link className="nav-link" to="/admin/GetCoupon">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                Get Coupon
              </Link>

              <Link className="nav-link" to="/admin/reports">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                Reports
              </Link>
           
              <Link className="nav-link" to="/admin/AddNotification">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                AddNotification
              </Link>

              <Link className="nav-link" to="/admin/UpdateVersion">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
               Update Version
              </Link>
              <Link className="nav-link" to="/admin/AddDeliveryCost">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                Add Delivery Cost
              </Link>
              <Link className="nav-link" to="/admin/AddBankDetails">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                Add Bank Details
              </Link>
           
              <button className="logout-button" onClick={handleLogout}>
                Logout
              </button>
            </div>
          </div>
          <div className="sb-sidenav-footer">
            <div className="small">Logged in as:</div>
            Admin
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Sidebar;




{/* <Link
className="nav-link collapsed"
to="/"
data-bs-toggle="collapse"
data-bs-target="#collapseOrder"
aria-expanded="false"
aria-controls="collapseOrder"
>
<div className="sb-nav-link-icon">
  <i className="fas fa-columns"></i>
</div>
Order
<div className="sb-sidenav-collapse-arrow">
  <i className="fas fa-angle-down"></i>
</div>
</Link>
<div
className="collapse"
id="collapseOrder"
aria-labelledby="headingOne"
data-bs-parent="#sidenavAccordion"
>
<nav className="sb-sidenav-menu-nested nav">
  <Link className="nav-link" to="/admin/OrderList">
    Order List
  </Link>
  <Link className="nav-link" to="/admin/OrderTracking">
    Order Tracking
  </Link>
  <Link className="nav-link" to="/admin/OrderStatus">
    Order Status
  </Link>
</nav>
</div> */}