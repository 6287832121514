// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Modal, Button } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import apiConfig from "../../../apiConfig";

// const AddProduct = () => {
//   const [parentIds, setParentIds] = useState([]);
//   const [selectedParentId, setSelectedParentId] = useState("");
//   const [subcategories, setSubcategories] = useState([]);
//   const [selectedSubcategory, setSelectedSubcategory] = useState("");
//   const [brands, setBrands] = useState([]);
//   const [selectedBrand, setSelectedBrand] = useState("");
//   const [showModal, setShowModal] = useState(false);
//   const [specifications, setSpecifications] = useState([]);
//   const [selectedSpecificationKey, setSelectedSpecificationKey] = useState("");
//   const [values, setValues] = useState({});
//   const [formData, setFormData] = useState({
//     title: "",
//     brand: "",
//     description: "",
//     regularPrice: "",
//     salePrice: "",
//     stock: "",
//     category: "",
//     productimage1: null,
//     productimage2: null,
//     productimage3: null,
//   });

//   useEffect(() => {
//     const fetchParentIds = async () => {
//       try {
//         const response = await axios.get(`${apiConfig.baseUrl}${apiConfig.getCategory}`);
//         setParentIds(response.data);
//         if (response.data.length > 0) {
//           setSelectedParentId(response.data[0]._id);
//         }
//       } catch (error) {
//         console.error("Error fetching parent IDs:", error);
//       }
//     };

//     fetchParentIds();
//   }, []);

//   useEffect(() => {
//     const fetchSubcategories = async () => {
//       try {
//         if (selectedParentId) {
//           const response = await axios.get(`${apiConfig.baseUrl}${apiConfig.getSubCategory}${selectedParentId}`);
//           setSubcategories(response.data);
//         }
//       } catch (error) {
//         console.error("Error fetching subcategories:", error);
//       }
//     };

//     fetchSubcategories();
//   }, [selectedParentId]);

//   useEffect(() => {
//     const fetchBrands = async () => {
//       try {
//         const response = await axios.get("https://www.testbharatcod.com/admin/getNewBrands");
//         setBrands(response.data);
//         if (response.data.length > 0) {
//           setSelectedBrand(""); // Ensure it's empty initially to prompt the user to select
//         }
//       } catch (error) {
//         console.error("Error fetching brands:", error);
//       }
//     };

//     fetchBrands();
//   }, []);

//   useEffect(() => {
//     const fetchSpecifications = async () => {
//       try {
//         const response = await axios.get(`${apiConfig.baseUrl}/admin/GetAllspecificationkeys`);
//         if (response.data && Array.isArray(response.data.data)) {
//           setSpecifications(response.data.data);
//         } else {
//           console.error("Specifications data is not an array or is missing:", response.data);
//           setSpecifications([]);
//         }
//       } catch (error) {
//         console.error("Error fetching specifications:", error);
//         setSpecifications([]);
//       }
//     };

//     fetchSpecifications();
//   }, []);

//   const handleCloseModal = () => {
//     setShowModal(false);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     if (name === "category") {
//       const selectedCategory = parentIds.find((category) => category.name === value);
//       setSelectedParentId(selectedCategory ? selectedCategory._id : "");
//     }
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleImageChange = (e) => {
//     const name = e.target.name;
//     const file = e.target.files[0];
//     setFormData({
//       ...formData,
//       [name]: file,
//     });
//   };

//   const handleSpecificationChange = (e) => {
//     const key = e.target.value;
//     setSelectedSpecificationKey(key);
//   };

//   const handleValueChange = (e) => {
//     const { name, value } = e.target;
//     setValues({
//       ...values,
//       [name]: value,
//     });
//   };

//   const handleAddSpecification = async () => {
//     const formDataToSend = new FormData();
//     formDataToSend.append("title", formData.title);
//     formDataToSend.append("brand", selectedBrand);
//     formDataToSend.append("description", formData.description);
//     formDataToSend.append("regularPrice", formData.regularPrice);
//     formDataToSend.append("salePrice", formData.salePrice);
//     formDataToSend.append("stock", formData.stock);
//     formDataToSend.append("category", formData.category);
//     formDataToSend.append("subcategory", selectedSubcategory);
//     formDataToSend.append("productimage1", formData.productimage1);
//     formDataToSend.append("productimage2", formData.productimage2);
//     formDataToSend.append("productimage3", formData.productimage3);

//     // Convert specifications to an array of key-value pairs
//     const specificationsArray = Object.entries(values).map(([key, value]) => ({
//       key,
//       value,
//     }));

//     // Check if the specifications array is in the expected format
//     console.log("Specifications Array:", specificationsArray);

//     formDataToSend.append("specifications", JSON.stringify(specificationsArray));

//     // Log the FormData entries
//     console.log("Sending data:", Array.from(formDataToSend.entries()));

//     try {
//       await axios.post(`${apiConfig.baseUrl}${apiConfig.addProducts}`, formDataToSend, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       setShowModal(true);
//       // Reset form data
//       setFormData({
//         title: "",
//         brand: "",
//         description: "",
//         regularPrice: "",
//         salePrice: "",
//         stock: "",
//         category: "",
//         productimage1: null,
//         productimage2: null,
//         productimage3: null,
//       });
//       setValues({});
//       setSelectedSpecificationKey("");
//     } catch (error) {
//       console.error("Error adding product:", error.response.data);
//     }
//   };

//   return (
//     <div className="container-fluid px-4">
//       <form onSubmit={(e) => e.preventDefault()}>
//         <div className="form-group mb-3">
//           <label htmlFor="category">Category</label>
//           <select
//             id="category"
//             name="category"
//             className="form-control"
//             value={formData.category}
//             onChange={handleChange}
//             required
//           >
//             <option value="">Select Parent Category</option>
//             {parentIds.map((category) => (
//               <option key={category._id} value={category.name}>
//                 {category.name}
//               </option>
//             ))}
//           </select>
//         </div>

//         <div className="form-group mb-3">
//           <label htmlFor="subcategory">Subcategory</label>
//           {selectedParentId && (
//             <select
//               id="subcategory"
//               name="subcategory"
//               className="form-control"
//               value={selectedSubcategory}
//               onChange={(e) => setSelectedSubcategory(e.target.value)}
//             >
//               <option value="">Select Subcategory</option>
//               {subcategories.map((subcategory) => (
//                 <option key={subcategory._id} value={subcategory.name}>
//                   {subcategory.name}
//                 </option>
//               ))}
//             </select>
//           )}
//         </div>

//         <div className="form-group mb-3">
//           <label htmlFor="title">Title</label>
//           <input
//             placeholder="Enter Product Title"
//             type="text"
//             id="title"
//             name="title"
//             className="form-control"
//             value={formData.title}
//             onChange={handleChange}
//             required
//           />
//         </div>

//         <div className="form-group mb-3">
//           <label htmlFor="brand">Brand</label>
//           <select
//             id="brand"
//             name="brand"
//             className="form-control"
//             value={selectedBrand}
//             onChange={(e) => setSelectedBrand(e.target.value)}
//             required
//           >
//             <option value="" disabled>Select Brand</option>
//             {brands.map((brand) => (
//               <option key={brand._id} value={brand._id}>
//                 {brand.name}
//               </option>
//             ))}
//           </select>
//         </div>

//         <div className="form-group mb-3" style={{ width: "50%" }}>
//           <label htmlFor="description">Description</label>
//           <textarea
//             placeholder="Write description"
//             id="description"
//             name="description"
//             className="form-control"
//             value={formData.description}
//             onChange={handleChange}
//             required
//           />
//         </div>

//         <div className="form-group mb-3">
//           <label htmlFor="regularPrice">Regular Price</label>
//           <input
//             placeholder="Enter Regular Price"
//             type="number"
//             id="regularPrice"
//             name="regularPrice"
//             className="form-control"
//             value={formData.regularPrice}
//             onChange={handleChange}
//             required
//           />
//         </div>

//         <div className="form-group mb-3">
//           <label htmlFor="salePrice">Sale Price</label>
//           <input
//             placeholder="Enter Sale Price"
//             type="number"
//             id="salePrice"
//             name="salePrice"
//             className="form-control"
//             value={formData.salePrice}
//             onChange={handleChange}
//             required
//           />
//         </div>

//         <div className="form-group mb-3">
//           <label htmlFor="stock">Stock</label>
//           <input
//             placeholder="Enter Stock Quantity"
//             type="number"
//             id="stock"
//             name="stock"
//             className="form-control"
//             value={formData.stock}
//             onChange={handleChange}
//             required
//           />
//         </div>

//         <div className="form-group mb-3">
//           <label htmlFor="productimage1">Product Image 1</label>
//           <input
//             type="file"
//             id="productimage1"
//             name="productimage1"
//             className="form-control"
//             onChange={handleImageChange}
//           />
//         </div>

//         <div className="form-group mb-3">
//           <label htmlFor="productimage2">Product Image 2</label>
//           <input
//             type="file"
//             id="productimage2"
//             name="productimage2"
//             className="form-control"
//             onChange={handleImageChange}
//           />
//         </div>

//         <div className="form-group mb-3">
//           <label htmlFor="productimage3">Product Image 3</label>
//           <input
//             type="file"
//             id="productimage3"
//             name="productimage3"
//             className="form-control"
//             onChange={handleImageChange}
//           />
//         </div>

//         <div className="form-group mb-3">
//           <label htmlFor="specifications">Specifications</label>
//           <select
//             id="specifications"
//             name="specifications"
//             className="form-control"
//             value={selectedSpecificationKey}
//             onChange={handleSpecificationChange}
//           >
//             <option value="">Select Specification</option>
//             {specifications.map((spec) => (
//               <option key={spec._id} value={spec.key}>
//                 {spec.key}
//               </option>
//             ))}
//           </select>
//         </div>

//         {selectedSpecificationKey && (
//           <div className="form-group mb-3">
//             <label htmlFor="specificationValue">Specification Value</label>
//             <input
//               placeholder="Enter Specification Value"
//               type="text"
//               id="specificationValue"
//               name={selectedSpecificationKey}
//               className="form-control"
//               value={values[selectedSpecificationKey] || ""}
//               onChange={handleValueChange}
//             />
//           </div>
//         )}

//         <button type="button" className="btn btn-primary" onClick={handleAddSpecification}>
//           Add Product
//         </button>
//       </form>

//       <Modal show={showModal} onHide={handleCloseModal}>
//         <Modal.Header closeButton>
//           <Modal.Title>Success</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>Product added successfully!</Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleCloseModal}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// };

// export default AddProduct;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import "bootstrap/dist/css/bootstrap.min.css";
// import apiConfig from "../../../apiConfig";
// import { useNavigate } from "react-router-dom";

// const AddProduct = () => {
//   const navigate = useNavigate();
//   const [parentIds, setParentIds] = useState([]);
//   const [selectedParentId, setSelectedParentId] = useState("");
//   const [subcategories, setSubcategories] = useState([]);
//   const [selectedSubcategory, setSelectedSubcategory] = useState("");
//   const [brands, setBrands] = useState([]);
//   const [selectedBrand, setSelectedBrand] = useState("");
//   const [showModal, setShowModal] = useState(false);
//   const [specifications, setSpecifications] = useState([]);
//   const [specificationEntries, setSpecificationEntries] = useState([]);
//   const [variations, setVariations] = useState([]);

//   const [formData, setFormData] = useState({
//     title: "",
//     brand: "",
//     description: "",
//     regularPrice: "",
//     salePrice: "",
//     stock: "",
//     category: "",
//     subcategory: "",
//     productimage1: null,
//     productimage2: null,
//     productimage3: null,
//   });

//   useEffect(() => {
//     const fetchParentIds = async () => {
//       try {
//         const response = await axios.get(
//           `${apiConfig.baseUrl}${apiConfig.getCategory}`
//         );
//         setParentIds(response.data);
//         if (response.data.length > 0) {
//           setSelectedParentId(response.data[0]._id);
//         }
//       } catch (error) {
//         console.error("Error fetching parent IDs:", error);
//       }
//     };

//     fetchParentIds();
//   }, []);

//   useEffect(() => {
//     const fetchSubcategories = async () => {
//       try {
//         if (selectedParentId) {
//           const response = await axios.get(
//             `${apiConfig.baseUrl}${apiConfig.getSubCategory}${selectedParentId}`
//           );
//           setSubcategories(response.data);
//         }
//       } catch (error) {
//         console.error("Error fetching subcategories:", error);
//       }
//     };

//     fetchSubcategories();
//   }, [selectedParentId]);

//   useEffect(() => {
//     const fetchBrands = async () => {
//       try {
//         const response = await axios.get(
//           "https://www.testbharatcod.com/admin/getNewBrands"
//         );
//         setBrands(response.data);
//         if (response.data.length > 0) {
//           setSelectedBrand(""); // Ensure it's empty initially to prompt the user to select
//         }
//       } catch (error) {
//         console.error("Error fetching brands:", error);
//       }
//     };

//     fetchBrands();
//   }, []);

//   useEffect(() => {
//     const fetchSpecifications = async () => {
//       try {
//         const response = await axios.get(
//           `${apiConfig.baseUrl}/admin/GetAllspecificationkeys`
//         );
//         if (response.data && Array.isArray(response.data.data)) {
//           setSpecifications(response.data.data.map((spec) => spec.key));
//         } else {
//           console.error(
//             "Specifications data is not an array or is missing:",
//             response.data
//           );
//           setSpecifications([]);
//         }
//       } catch (error) {
//         console.error("Error fetching specifications:", error);
//         setSpecifications([]);
//       }
//     };

//     fetchSpecifications();
//   }, []);

//   const handleCloseModal = () => {
//     setShowModal(false);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     if (name === "category") {
//       const selectedCategory = parentIds.find(
//         (category) => category.name === value
//       );
//       setSelectedParentId(selectedCategory ? selectedCategory._id : "");
//     }
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleImageChange = (e) => {
//     const name = e.target.name;
//     const file = e.target.files[0];
//     setFormData({
//       ...formData,
//       [name]: file,
//     });
//   };

//   const handleAddSpecification = () => {
//     setSpecificationEntries((prevEntries) => [
//       ...prevEntries,
//       { key: "", values: [""] },
//     ]);
//   };

//   const handleRemoveSpecification = (index) => {
//     setSpecificationEntries((prevEntries) =>
//       prevEntries.filter((_, i) => i !== index)
//     );
//   };

//   const handleSpecificationChange = (index, event) => {
//     const { value } = event.target;
//     setSpecificationEntries((prevEntries) =>
//       prevEntries.map((entry, i) =>
//         i === index ? { ...entry, key: value } : entry
//       )
//     );
//   };

//   const handleValueChange = (specIndex, valueIndex, event) => {
//     const { value } = event.target;
//     setSpecificationEntries((prevEntries) =>
//       prevEntries.map((entry, i) =>
//         i === specIndex
//           ? {
//               ...entry,
//               values: entry.values.map((val, j) =>
//                 j === valueIndex ? value : val
//               ),
//             }
//           : entry
//       )
//     );
//   };

//   const handleAddValue = (index) => {
//     setSpecificationEntries((prevEntries) =>
//       prevEntries.map((entry, i) =>
//         i === index ? { ...entry, values: [...entry.values, ""] } : entry
//       )
//     );
//   };

//   const handleRemoveValue = (specIndex, valueIndex) => {
//     setSpecificationEntries((prevEntries) =>
//       prevEntries.map((entry, i) =>
//         i === specIndex
//           ? {
//               ...entry,
//               values: entry.values.filter((_, j) => j !== valueIndex),
//             }
//           : entry
//       )
//     );
//   };

//   const handleAddVariation = () => {
//     setVariations((prevVariations) => [
//       ...prevVariations,
//       { name: "", values: [""] },
//     ]);
//   };

//   const handleRemoveVariation = (index) => {
//     setVariations((prevVariations) =>
//       prevVariations.filter((_, i) => i !== index)
//     );
//   };

//   const handleVariationChange = (index, event) => {
//     const { value } = event.target;
//     setVariations((prevVariations) =>
//       prevVariations.map((variation, i) =>
//         i === index ? { ...variation, name: value } : variation
//       )
//     );
//   };

//   const handleVariationValueChange = (varIndex, valueIndex, event) => {
//     const { value } = event.target;
//     setVariations((prevVariations) =>
//       prevVariations.map((variation, i) =>
//         i === varIndex
//           ? {
//               ...variation,
//               values: variation.values.map((val, j) =>
//                 j === valueIndex ? value : val
//               ),
//             }
//           : variation
//       )
//     );
//   };

//   const handleAddVariationValue = (index) => {
//     setVariations((prevVariations) =>
//       prevVariations.map((variation, i) =>
//         i === index
//           ? { ...variation, values: [...variation.values, ""] }
//           : variation
//       )
//     );
//   };

//   const handleRemoveVariationValue = (varIndex, valueIndex) => {
//     setVariations((prevVariations) =>
//       prevVariations.map((variation, i) =>
//         i === varIndex
//           ? {
//               ...variation,
//               values: variation.values.filter((_, j) => j !== valueIndex),
//             }
//           : variation
//       )
//     );
//   };

//   const handleAddProduct = async () => {
//     const formDataToSend = new FormData();
//     formDataToSend.append("title", formData.title);
//     formDataToSend.append("brand", selectedBrand);
//     formDataToSend.append("description", formData.description);
//     formDataToSend.append("regularPrice", formData.regularPrice);
//     formDataToSend.append("salePrice", formData.salePrice);
//     formDataToSend.append("stock", formData.stock);
//     formDataToSend.append("category", formData.category);
//     formDataToSend.append("subcategory", selectedSubcategory);
//     formDataToSend.append("productimage1", formData.productimage1);
//     formDataToSend.append("productimage2", formData.productimage2);
//     formDataToSend.append("productimage3", formData.productimage3);

//     // Convert specifications to an array of key-value pairs
//     const specificationsArray = specificationEntries.map(({ key, values }) => ({
//       key,
//       values,
//     }));

//     formDataToSend.append(
//       "specifications",
//       JSON.stringify(specificationsArray)
//     );

//     // Convert variations to an array of key-value pairs
//     const variationsArray = variations.map(({ name, values }) => ({
//       name,
//       values,
//     }));

//     formDataToSend.append("variations", JSON.stringify(variationsArray));

//     try {
//       await axios.post(
//         `${apiConfig.baseUrl}${apiConfig.addProducts}`,
//         formDataToSend,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );
//       setShowModal(true);
//       // Reset form data
//       setFormData({
//         title: "",
//         brand: "",
//         description: "",
//         regularPrice: "",
//         salePrice: "",
//         stock: "",
//         category: "",
//         subcategory: "",
//         productimage1: null,
//         productimage2: null,
//         productimage3: null,
//       });
//       setSpecificationEntries([]);
//       setVariations([]);
//     } catch (error) {
//       console.error("Error adding product:", error);
//     }
//   };

//   return (
//     <div className="container">
//       <h2>Add Product</h2>

//       {/ Other form fields remain the same /}
//       <div className="form-group mb-3">
//         <label htmlFor="title">Title</label>
//         <input
//           type="text"
//           className="form-control"
//           id="title"
//           name="title"
//           value={formData.title}
//           onChange={handleChange}
//         />
//       </div>

//       <div className="form-group mb-3">
//         <label htmlFor="category">Category</label>
//         <select
//           id="category"
//           name="category"
//           className="form-control"
//           value={formData.category}
//           onChange={handleChange}
//         >
//           <option value="">Select Category</option>
//           {parentIds.map((parent) => (
//             <option key={parent._id} value={parent.name}>
//               {parent.name}
//             </option>
//           ))}
//         </select>
//       </div>

//       <div className="form-group mb-3">
//         <label htmlFor="subcategory">Subcategory</label>
//         <select
//           id="subcategory"
//           name="subcategory"
//           className="form-control"
//           value={selectedSubcategory}
//           onChange={(e) => setSelectedSubcategory(e.target.value)}
//         >
//           <option value="">Select Subcategory</option>
//           {subcategories.map((sub) => (
//             <option key={sub._id} value={sub.name}>
//               {sub.name}
//             </option>
//           ))}
//         </select>
//       </div>

//       <div className="form-group mb-3">
//         <label htmlFor="brand">Brand</label>
//         <select
//           id="brand"
//           name="brand"
//           className="form-control"
//           value={selectedBrand}
//           onChange={(e) => setSelectedBrand(e.target.value)}
//         >
//           <option value="">Select Brand</option>
//           {brands.map((brand) => (
//             <option key={brand._id} value={brand.name}>
//               {brand.name}
//             </option>
//           ))}
//         </select>
//       </div>

//       <div className="form-group mb-3">
//         <label htmlFor="description">Description</label>
//         <textarea
//           className="form-control"
//           id="description"
//           name="description"
//           rows="4"
//           value={formData.description}
//           onChange={handleChange}
//         />
//       </div>

//       <div className="form-group mb-3">
//         <label htmlFor="regularPrice">Regular Price</label>
//         <input
//           type="number"
//           className="form-control"
//           id="regularPrice"
//           name="regularPrice"
//           value={formData.regularPrice}
//           onChange={handleChange}
//         />
//       </div>

//       <div className="form-group mb-3">
//         <label htmlFor="salePrice">Sale Price</label>
//         <input
//           type="number"
//           className="form-control"
//           id="salePrice"
//           name="salePrice"
//           value={formData.salePrice}
//           onChange={handleChange}
//         />
//       </div>

//       <div className="form-group mb-3">
//         <label htmlFor="stock">Stock</label>
//         <input
//           type="number"
//           className="form-control"
//           id="stock"
//           name="stock"
//           value={formData.stock}
//           onChange={handleChange}
//         />
//       </div>

//       <div className="form-group mb-3">
//         <label htmlFor="productimage1">Product Image 1</label>
//         <input
//           type="file"
//           className="form-control"
//           id="productimage1"
//           name="productimage1"
//           onChange={handleImageChange}
//         />
//       </div>

//       <div className="form-group mb-3">
//         <label htmlFor="productimage2">Product Image 2</label>
//         <input
//           type="file"
//           className="form-control"
//           id="productimage2"
//           name="productimage2"
//           onChange={handleImageChange}
//         />
//       </div>

//       <div className="form-group mb-3">
//         <label htmlFor="productimage3">Product Image 3</label>
//         <input
//           type="file"
//           className="form-control"
//           id="productimage3"
//           name="productimage3"
//           onChange={handleImageChange}
//         />
//       </div>

//       {/* Specifications Section */}
//       <button
//         type="button"
//         className="btn btn-primary"
//         onClick={() => setShowModal(true)}
//       >
//         Add Specification
//       </button>

//       <div className="form-group mb-3">
//         <h5>Specifications</h5>
//         {specificationEntries.map((entry, index) => (
//           <div key={index} className="mb-3">
//             <div className="form-group">
//               <label>Key</label>
//               <input
//                 type="text"
//                 className="form-control"
//                 value={entry.key}
//                 onChange={(e) => handleSpecificationChange(index, e)}
//               />
//             </div>
//             {entry.values.map((value, valueIndex) => (
//               <div key={valueIndex} className="form-group mb-2">
//                 <label>Value</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   value={value}
//                   onChange={(e) => handleValueChange(index, valueIndex, e)}
//                 />
//                 <button
//                   type="button"
//                   className="btn btn-danger mt-2"
//                   onClick={() => handleRemoveValue(index, valueIndex)}
//                 >
//                   Remove Value
//                 </button>
//               </div>
//             ))}
//             <button
//               type="button"
//               className="btn btn-secondary"
//               onClick={() => handleAddValue(index)}
//             >
//               Add Value
//             </button>
//             <button
//               type="button"
//               className="btn btn-danger mt-2"
//               onClick={() => handleRemoveSpecification(index)}
//             >
//               Remove Specification
//             </button>
//           </div>
//         ))}
//         <button
//           type="button"
//           className="btn btn-success"
//           onClick={handleAddSpecification}
//         >
//           Add Specification
//         </button>
//       </div>

//       {/* Variations Section */}
//       <button
//         type="button"
//         className="btn btn-primary"
//         onClick={handleAddVariation}
//       >
//         Add Variation
//       </button>

//       <div className="form-group mb-3">
//         <h5>Variations</h5>
//         {variations.map((variation, index) => (
//           <div key={index} className="mb-3">
//             <div className="form-group">
//               <label>Variation Name</label>
//               <input
//                 type="text"
//                 className="form-control"
//                 value={variation.name}
//                 onChange={(e) => handleVariationChange(index, e)}
//               />
//             </div>
//             {variation.values.map((value, valueIndex) => (
//               <div key={valueIndex} className="form-group mb-2">
//                 <label>Value</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   value={value}
//                   onChange={(e) =>
//                     handleVariationValueChange(index, valueIndex, e)
//                   }
//                 />
//                 <button
//                   type="button"
//                   className="btn btn-danger mt-2"
//                   onClick={() => handleRemoveVariationValue(index, valueIndex)}
//                 >
//                   Remove Value
//                 </button>
//               </div>
//             ))}
//             <button
//               type="button"
//               className="btn btn-secondary"
//               onClick={() => handleAddVariationValue(index)}
//             >
//               Add Value
//             </button>
//             <button
//               type="button"
//               className="btn btn-danger mt-2"
//               onClick={() => handleRemoveVariation(index)}
//             >
//               Remove Variation
//             </button>
//           </div>
//         ))}
//         <button
//           type="button"
//           className="btn btn-success"
//           onClick={handleAddProduct}
//         >
//           Add Product
//         </button>
//       </div>
//     </div>
//   );
// };

// export default AddProduct;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Modal, Button } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import apiConfig from "../../../apiConfig";
// import { useNavigate } from "react-router-dom";

// const AddProduct = () => {
//   const navigate = useNavigate();
//   const [parentIds, setParentIds] = useState([]);
//   const [selectedParentId, setSelectedParentId] = useState("");
//   const [subcategories, setSubcategories] = useState([]);
//   const [selectedSubcategory, setSelectedSubcategory] = useState("");
//   const [brands, setBrands] = useState([]);
//   const [selectedBrand, setSelectedBrand] = useState("");
//   const [showModal, setShowModal] = useState(false);
//   const [specifications, setSpecifications] = useState([]);
//   const [specificationEntries, setSpecificationEntries] = useState([]);
//   const [formData, setFormData] = useState({
//     title: "",
//     brand: "",
//     description: "",
//     regularPrice: "",
//     salePrice: "",
//     stock: "",
//     category: "",
//     productimage1: null,
//     productimage2: null,
//     productimage3: null,
//     variations: [
//       {
//         color: "",
//         ram: "",
//         rom: "",
//         variationImages: [
//           {
//             productImage1: "variationImage1_0",
//             productImage2: "variationImage2_0",
//             productImage3: "variationImage3_0",
//           },
//         ],
//       },
//     ],
//     // productImage1: "variationImage1_0",
//     // productImage2: "variationImage2_0",
//     // productImage3: "variationImage3_0",
//   });

//   useEffect(() => {
//     const fetchParentIds = async () => {
//       try {
//         const response = await axios.get(
//           `${apiConfig.baseUrl}${apiConfig.getCategory}`
//         );
//         setParentIds(response.data);
//         if (response.data.length > 0) {
//           setSelectedParentId(response.data[0]._id);
//         }
//       } catch (error) {
//         console.error("Error fetching parent IDs:", error);
//       }
//     };

//     fetchParentIds();
//   }, []);

//   useEffect(() => {
//     const fetchSubcategories = async () => {
//       try {
//         if (selectedParentId) {
//           const response = await axios.get(
//             `${apiConfig.baseUrl}${apiConfig.getSubCategory}${selectedParentId}`
//           );
//           setSubcategories(response.data);
//           console.log("response.................", response.data);
//         }
//       } catch (error) {
//         console.error("Error fetching subcategories:", error);
//       }
//     };

//     fetchSubcategories();
//   }, [selectedParentId]);

//   useEffect(() => {
//     const fetchBrands = async () => {
//       try {
//         const response = await axios.get(
//           "https://www.testbharatcod.com/admin/getNewBrands"
//         );
//         setBrands(response.data);
//         console.log(response);

//         if (response.data.length > 0) {
//           setSelectedBrand(""); // Ensure it's empty initially to prompt the user to select
//         }
//       } catch (error) {
//         console.error("Error fetching brands:", error);
//       }
//     };

//     fetchBrands();
//   }, []);

//   useEffect(() => {
//     const fetchSpecifications = async () => {
//       try {
//         const response = await axios.get(
//           `${apiConfig.baseUrl}/admin/GetAllspecificationkeys`
//         );
//         if (response.data && Array.isArray(response.data.data)) {
//           setSpecifications(response.data.data.map((spec) => spec.key)); // Adjust based on your actual API response
//         } else {
//           console.error(
//             "Specifications data is not an array or is missing:",
//             response.data
//           );
//           setSpecifications([]);
//         }
//       } catch (error) {
//         console.error("Error fetching specifications:", error);
//         setSpecifications([]);
//       }
//     };

//     fetchSpecifications();
//   }, []);

//   const handleCloseModal = () => {
//     setShowModal(false);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     if (name === "category") {
//       const selectedCategory = parentIds.find(
//         (category) => category.name === value
//       );
//       setSelectedParentId(selectedCategory ? selectedCategory._id : "");
//     }
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleImageChange = (e) => {
//     const { name, files } = e.target;
//     setFormData({
//       ...formData,
//       [name]: files[0], // files[0] handles single file input; modify if handling multiple
//     });
//   };

//   const handleAddSpecification = () => {
//     setSpecificationEntries((prevEntries) => [
//       ...prevEntries,
//       { key: "", values: [""] },
//     ]);
//   };

//   const handleRemoveSpecification = (index) => {
//     setSpecificationEntries((prevEntries) =>
//       prevEntries.filter((_, i) => i !== index)
//     );
//   };

//   const handleSpecificationChange = (index, event) => {
//     const { value } = event.target;
//     setSpecificationEntries((prevEntries) =>
//       prevEntries.map((entry, i) =>
//         i === index ? { ...entry, key: value } : entry
//       )
//     );
//   };

//   const handleValueChange = (specIndex, valueIndex, event) => {
//     const { value } = event.target;
//     setSpecificationEntries((prevEntries) =>
//       prevEntries.map((entry, i) =>
//         i === specIndex
//           ? {
//               ...entry,
//               values: entry.values.map((val, j) =>
//                 j === valueIndex ? value : val
//               ),
//             }
//           : entry
//       )
//     );
//   };

//   const handleAddValue = (index) => {
//     setSpecificationEntries((prevEntries) =>
//       prevEntries.map((entry, i) =>
//         i === index ? { ...entry, values: [...entry.values, ""] } : entry
//       )
//     );
//   };

//   const handleRemoveValue = (specIndex, valueIndex) => {
//     setSpecificationEntries((prevEntries) =>
//       prevEntries.map((entry, i) =>
//         i === specIndex
//           ? {
//               ...entry,
//               values: entry.values.filter((_, j) => j !== valueIndex),
//             }
//           : entry
//       )
//     );
//   };

//   const handleVariationChange = (index, field, value) => {
//     setFormData((prevFormData) => {
//       const updatedVariations = [...prevFormData.variations];
//       updatedVariations[index] = {
//         ...updatedVariations[index],
//         [field]: value,
//       };
//       return {
//         ...prevFormData,
//         variations: updatedVariations,
//       };
//     });
//   };

//   const handleAddProduct = async () => {
//     const formDataToSend = new FormData();
//     formDataToSend.append("title", formData.title);
//     formDataToSend.append("brand", selectedBrand);
//     formDataToSend.append("description", formData.description);
//     formDataToSend.append("regularPrice", formData.regularPrice);
//     formDataToSend.append("salePrice", formData.salePrice);
//     formDataToSend.append("stock", formData.stock);
//     formDataToSend.append("category", formData.category);
//     formDataToSend.append("subcategory", selectedSubcategory);
//     formDataToSend.append("productimage1", formData.productimage1);
//     formDataToSend.append("productimage2", formData.productimage2);
//     formDataToSend.append("productimage3", formData.productimage3);
//     formDataToSend.append("variations", JSON.stringify(formData.variations));
//     formDataToSend.append("variationImage1_0", formData.variationImage1_0);
//     formDataToSend.append("variationImage2_0", formData.variationImage2_0);
//     formDataToSend.append("variationImage3_0", formData.variationImage3_0);
//     formDataToSend.append("variationImage1_1", formData.variationImage1_1);
//     formDataToSend.append("variationImage2_1", formData.variationImage2_1);
//     formDataToSend.append("variationImage3_1", formData.variationImage3_1);

//     console.log("variations", formData.variations);

//     try {
//       const response = await axios.post(
//         `${apiConfig.baseUrl}${apiConfig.addProducts}`,
//         formDataToSend,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );
//       console.log("Product added successfully:", response.data);
//       setShowModal(true);
//       // Reset form data
//       setFormData({
//         title: "",
//         brand: "",
//         description: "",
//         regularPrice: "",
//         salePrice: "",
//         stock: "",
//         category: "",
//         productimage1: null,
//         productimage2: null,
//         productimage3: null,
//         variations: [
//           {
//             color: "",
//             ram: "",
//             rom: "",
//             // variationImage1_0: "",
//             // variationImage2_0: "",
//             // variationImage3_0: "",
//             // variationImage1_1: "",
//             // variationImage2_1: "",
//             // variationImage3_1: "",
//           },
//         ],
//       });
//       setSpecificationEntries([]);
//     } catch (error) {
//       console.error(
//         "Error adding product:",
//         error.response?.data || error.message
//       );
//     }
//   };

//   const handleVariation = () => {
//     setFormData((prevState) => ({
//       ...prevState,
//       variations: [
//         ...prevState.variations,
//         { color: "", size: "", ram: "", rom: "", images: [] },
//       ],
//     }));
//   };

//   const handleRemoveVariation = (index) => {
//     setFormData((prevState) => ({
//       ...prevState,
//       variations: prevState.variations.filter((_, i) => i !== index),
//     }));
//   };

//   const handleImageChange1 = (index, event) => {
//     const files = Array.from(event.target.files);
//     setFormData((prevState) => {
//       const updatedVariations = [...prevState.variations];
//       updatedVariations[index] = {
//         ...updatedVariations[index],
//         images: files, // Add the images to the specific variation
//       };
//       return { ...prevState, variations: updatedVariations };
//     });
//   };

//   return (
//     <div className="container-fluid px-4">
//       <form onSubmit={(e) => e.preventDefault()}>
//         <div className="form-group mb-3">
//           <label htmlFor="title">Title</label>
//           <input
//             type="text"
//             className="form-control"
//             id="title"
//             name="title"
//             value={formData.title}
//             onChange={handleChange}
//           />
//         </div>

//         <div className="form-group mb-3">
//           <label htmlFor="category">Category</label>
//           <select
//             id="category"
//             name="category"
//             className="form-control"
//             value={formData.category}
//             onChange={handleChange}
//           >
//             <option value="">Select Category</option>
//             {parentIds.map((parent) => (
//               <option key={parent._id} value={parent.name}>
//                 {parent.name}
//               </option>
//             ))}
//           </select>
//         </div>

//         <div className="form-group mb-3">
//           <label htmlFor="subcategory">Subcategory</label>
//           <select
//             id="subcategory"
//             name="subcategory"
//             className="form-control"
//             value={selectedSubcategory}
//             onChange={(e) => setSelectedSubcategory(e.target.value)}
//           >
//             <option value="">Select Subcategory</option>
//             {subcategories.map((subcategory) => (
//               <option key={subcategory.name} value={subcategory.name}>
//                 {subcategory.name}
//               </option>
//             ))}
//           </select>
//         </div>

//         <div className="form-group mb-3">
//           <label htmlFor="brand">Brand</label>
//           <select
//             id="brand"
//             name="brand"
//             className="form-control"
//             value={selectedBrand}
//             onChange={(e) => setSelectedBrand(e.target.value)}
//           >
//             <option value="">Select Brand</option>
//             {brands.map((brand) => (
//               <option key={brand.name} value={brand.name}>
//                 {brand.name}
//               </option>
//             ))}
//           </select>
//         </div>

//         <div className="form-group mb-3">
//           <label htmlFor="description">Description</label>
//           <textarea
//             className="form-control"
//             id="description"
//             name="description"
//             value={formData.description}
//             onChange={handleChange}
//           />
//         </div>

//         <div className="form-group mb-3">
//           <label htmlFor="regularPrice">Regular Price</label>
//           <input
//             type="text"
//             className="form-control"
//             id="regularPrice"
//             name="regularPrice"
//             value={formData.regularPrice}
//             onChange={handleChange}
//           />
//         </div>

//         <div className="form-group mb-3">
//           <label htmlFor="salePrice">Sale Price</label>
//           <input
//             type="text"
//             className="form-control"
//             id="salePrice"
//             name="salePrice"
//             value={formData.salePrice}
//             onChange={handleChange}
//           />
//         </div>

//         <div className="form-group mb-3">
//           <label htmlFor="stock">Stock</label>
//           <input
//             type="text"
//             className="form-control"
//             id="stock"
//             name="stock"
//             value={formData.stock}
//             onChange={handleChange}
//           />
//         </div>

//         <div className="form-group mb-3">
//           <label htmlFor="productimage1">Product Image 1</label>
//           <input
//             type="file"
//             className="form-control"
//             id="productimage1"
//             name="productimage1"
//             onChange={handleImageChange}
//           />
//         </div>

//         <div className="form-group mb-3">
//           <label htmlFor="productimage2">Product Image 2</label>
//           <input
//             type="file"
//             className="form-control"
//             id="productimage2"
//             name="productimage2"
//             onChange={handleImageChange}
//           />
//         </div>

//         <div className="form-group mb-3">
//           <label htmlFor="productimage3">Product Image 3</label>
//           <input
//             type="file"
//             className="form-control"
//             id="productimage3"
//             name="productimage3"
//             onChange={handleImageChange}
//           />
//         </div>

//         <div className="form-group mb-3">
//           <label htmlFor="specifications">Specifications</label>
//           <div className="d-flex align-items-center mb-2">
//             <button
//               type="button"
//               className="btn btn-primary ms-2"
//               onClick={handleAddSpecification}
//             >
//               Add Specification
//             </button>
//           </div>
//           {specificationEntries.map((specification, specIndex) => (
//             <div key={specIndex} className="mb-2">
//               <div className="d-flex mb-2">
//                 <select
//                   className="form-control me-2"
//                   value={specification.key}
//                   onChange={(event) =>
//                     handleSpecificationChange(specIndex, event)
//                   }
//                 >
//                   <option value="">Select Key</option>
//                   {specifications.map((specKey) => (
//                     <option key={specKey} value={specKey}>
//                       {specKey}
//                     </option>
//                   ))}
//                 </select>
//                 <button
//                   type="button"
//                   className="btn btn-danger"
//                   onClick={() => handleRemoveSpecification(specIndex)}
//                 >
//                   Remove Specification
//                 </button>
//               </div>
//               {specification.values.map((value, valueIndex) => (
//                 <div key={valueIndex} className="d-flex mb-2">
//                   <input
//                     type="text"
//                     placeholder="Value"
//                     className="form-control me-2"
//                     value={value}
//                     onChange={(event) =>
//                       handleValueChange(specIndex, valueIndex, event)
//                     }
//                     required
//                   />
//                   <button
//                     type="button"
//                     className="btn btn-danger"
//                     onClick={() => handleRemoveValue(specIndex, valueIndex)}
//                   >
//                     Remove Value
//                   </button>
//                 </div>
//               ))}
//               <button
//                 type="button"
//                 className="btn btn-primary mb-2"
//                 onClick={() => handleAddValue(specIndex)}
//               >
//                 Add Value
//               </button>
//             </div>
//           ))}
//         </div>

//         {formData.variations.map((variation, index) => (
//           <>
//             <form key={index}>
//               <div>
//                 <label>Color:</label>
//                 <input
//                   type="text"
//                   name="color"
//                   value={variation.color}
//                   onChange={(e) =>
//                     handleVariationChange(index, "color", e.target.value)
//                   }
//                   required
//                   className="form-control"
//                 />
//               </div>
//               <div className="form-group mb-3">
//                 <label htmlFor="size">Size:</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="size"
//                   name="size"
//                   value={variation.size}
//                   onChange={(e) =>
//                     handleVariationChange(index, "size", e.target.value)
//                   }
//                 />
//               </div>
//               <div className="form-group mb-3">
//                 <label htmlFor="ram">RAM:</label>
//                 <input
//                   type="text"
//                   name="ram"
//                   value={variation.ram}
//                   onChange={(e) =>
//                     handleVariationChange(index, "ram", e.target.value)
//                   }
//                   required
//                   className="form-control"
//                 />
//               </div>
//               <div className="form-group mb-3">
//                 <label htmlFor="rom">ROM:</label>
//                 <input
//                   type="text"
//                   name="rom"
//                   value={variation.rom}
//                   onChange={(e) =>
//                     handleVariationChange(index, "rom", e.target.value)
//                   }
//                   required
//                   className="form-control"
//                 />
//               </div>
//               <div className="form-group mb-3">
//                 <label htmlFor={`variationImage1_0`}>Product Image 1</label>
//                 <input
//                   type="file"
//                   className="form-control"
//                   id={`variationImage1_0`}
//                   name={`variationImage1_0`}
//                   onChange={(e) => handleImageChange1(index, e)}
//                   multiple
//                 />
//               </div>
//               <div className="form-group mb-3">
//                 <label htmlFor={`variationImage2_0`}>Product Image 2</label>
//                 <input
//                   type="file"
//                   className="form-control"
//                   id={`variationImage2_0`}
//                   name={`variationImage2_0`}
//                   onChange={(e) => handleImageChange1(index, e)}
//                   multiple
//                 />
//               </div>
//               <div className="form-group mb-3">
//                 <label htmlFor={`variationImage3_0`}>Product Image 3</label>
//                 <input
//                   type="file"
//                   multiple
//                   onChange={(e) => handleImageChange1(index, e)}
//                   className="form-control mt-2"
//                 />
//               </div>
//             </form>
//             <button
//               type="button"
//               className="btn btn-danger"
//               onClick={() => handleRemoveVariation(index)}
//             >
//               Remove Variation
//             </button>
//           </>
//         ))}

//         <div style={{ display: "flex", margin: 10 }}>
//           <button
//             type="button"
//             className="btn btn-primary"
//             onClick={() => handleVariation()}
//           >
//             Add Variation
//           </button>
//         </div>

//         <div style={{ display: "flex", margin: 10 }}>
//           <button
//             type="button"
//             className="btn btn-primary"
//             onClick={handleAddProduct}
//           >
//             Add Product
//           </button>
//         </div>
//       </form>

//       <Modal show={showModal} onHide={handleCloseModal}>
//         <Modal.Header closeButton>
//           <Modal.Title>Product Added</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>Your product has been added successfully!</Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleCloseModal}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// };

// export default AddProduct;
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import apiConfig from "../../../apiConfig";
import { useNavigate } from "react-router-dom";

const AddProduct = () => {
  const navigate = useNavigate();
  const [parentIds, setParentIds] = useState([]);
  const [selectedParentId, setSelectedParentId] = useState("");
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [specifications, setSpecifications] = useState([]);
  const [specificationEntries, setSpecificationEntries] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    brand: "",
    description: "",
    regularPrice: "",
    salePrice: "",
    stock: "",
    category: "",
    variations: [
      {
        color: "",
        ram: "",
        rom: "",
      },
    ],
    variationImage1: null,
    variationImage2: null,
    variationImage3: null,
    // productImage1: "variationImage1_0",
    // productImage2: "variationImage2_0",
    // productImage3: "variationImage3_0",
  });

  console.log("specificationEntries", specifications);

  useEffect(() => {
    const fetchParentIds = async () => {
      try {
        const response = await axios.get(
          `${apiConfig.baseUrl}${apiConfig.getCategory}`
        );
        setParentIds(response.data);
        if (response.data.length > 0) {
          setSelectedParentId(response.data[0]._id);
        }
      } catch (error) {
        console.error("Error fetching parent IDs:", error);
      }
    };

    fetchParentIds();
  }, []);

  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        if (selectedParentId) {
          const response = await axios.get(
            `${apiConfig.baseUrl}${apiConfig.getSubCategory}${selectedParentId}`
          );
          setSubcategories(response.data);
          console.log("response.................", response.data);
        }
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    };

    fetchSubcategories();
  }, [selectedParentId]);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await axios.get(
          "https://www.testbharatcod.com/admin/getNewBrands"
        );
        setBrands(response.data);
        console.log(response);

        if (response.data.length > 0) {
          setSelectedBrand(""); // Ensure it's empty initially to prompt the user to select
        }
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };

    fetchBrands();
  }, []);

  useEffect(() => {
    const fetchSpecifications = async () => {
      try {
        const response = await axios.get(
          `${apiConfig.baseUrl}/admin/GetAllspecificationkeys`
        );
        if (response.data && Array.isArray(response.data.data)) {
          setSpecifications(response.data.data.map((spec) => spec.key)); // Adjust based on your actual API response
        } else {
          console.error(
            "Specifications data is not an array or is missing:",
            response.data
          );
          setSpecifications([]);
        }
      } catch (error) {
        console.error("Error fetching specifications:", error);
        setSpecifications([]);
      }
    };

    fetchSpecifications();
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "category") {
      const selectedCategory = parentIds.find(
        (category) => category.name === value
      );
      setSelectedParentId(selectedCategory ? selectedCategory._id : "");
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const handleAddSpecification = () => {
    setSpecificationEntries((prevEntries) => [
      ...prevEntries,
      { key: "", values: [""] },
    ]);
  };

  const handleRemoveSpecification = (index) => {
    setSpecificationEntries((prevEntries) =>
      prevEntries.filter((_, i) => i !== index)
    );
  };

  const handleSpecificationChange = (index, event) => {
    const { value } = event.target;
    setSpecificationEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index ? { ...entry, key: value } : entry
      )
    );
  };

  const handleValueChange = (specIndex, valueIndex, event) => {
    const { value } = event.target;
    setSpecificationEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === specIndex
          ? {
              ...entry,
              values: entry.values.map((val, j) =>
                j === valueIndex ? value : val
              ),
            }
          : entry
      )
    );
  };

  const handleAddValue = (index) => {
    setSpecificationEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index ? { ...entry, values: [...entry.values, ""] } : entry
      )
    );
  };

  const handleRemoveValue = (specIndex, valueIndex) => {
    setSpecificationEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === specIndex
          ? {
              ...entry,
              values: entry.values.filter((_, j) => j !== valueIndex),
            }
          : entry
      )
    );
  };

  console.log("specifications", formData.variations);

  const handleVariationChange = (index, field, value) => {
    setFormData((prevFormData) => {
      const updatedVariations = [...prevFormData.variations];
      updatedVariations[index] = {
        ...updatedVariations[index],
        [field]: value,
      };
      return {
        ...prevFormData,
        variations: updatedVariations,
      };
    });
  };

  const handleAddProduct = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("title", formData.title);
    formDataToSend.append("brand", selectedBrand);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("regularPrice", formData.regularPrice);
    formDataToSend.append("salePrice", formData.salePrice);
    formDataToSend.append("stock", formData.stock);
    formDataToSend.append("category", formData.category);
    formDataToSend.append("subcategory", selectedSubcategory);
    formDataToSend.append("productimage1", formData.productimage1);
    formDataToSend.append("productimage2", formData.productimage2);
    formDataToSend.append("productimage3", formData.productimage3);
    formDataToSend.append("variations", JSON.stringify(formData.variations));
    formDataToSend.append(
      "specifications",
      JSON.stringify(specificationEntries)
    );

    formData.variations.forEach((variation, index) => {
      if (variation.variationImage1) {
        formDataToSend.append(
          `variationImage1_${index}`,
          variation.variationImage1
        );
      }
      if (variation.variationImage2) {
        formDataToSend.append(
          `variationImage2_${index}`,
          variation.variationImage2
        );
      }
      if (variation.variationImage3) {
        formDataToSend.append(
          `variationImage3_${index}`,
          variation.variationImage3
        );
      }
    });

    try {
      const response = await axios.post(
        `${apiConfig.baseUrl}${apiConfig.addProducts}`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Product added successfully:", response.data);
      setShowModal(true);
      // Reset form data
      setFormData({
        title: "",
        brand: "",
        description: "",
        regularPrice: "",
        salePrice: "",
        stock: "",
        category: "",
        productimage1: null,
        productimage2: null,
        productimage3: null,
        variations: [
          {
            color: "",
            ram: "",
            rom: "",
          },
        ],
      });
      setSpecificationEntries([]);
    } catch (error) {
      console.error(
        "Error adding product:",
        error.response?.data || error.message
      );
    }
  };

  const handleVariation = () => {
    setFormData((prevState) => ({
      ...prevState,
      variations: [
        ...prevState.variations,
        { color: "", size: "", ram: "", rom: "" },
      ],
    }));
  };

  const handleRemoveVariation = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      variations: prevState.variations.filter((_, i) => i !== index),
    }));
  };

  const handleImageChange1 = (index, event) => {
    const files = event.target.files;
    const updatedImages = [...formData.variations];

    // Handle the first image input for this index
    if (event.target.id === `variationImage1_${index}`) {
      updatedImages[index].variationImage1 = files[0]; // For single image upload
    } else if (event.target.id === `variationImage2_${index}`) {
      updatedImages[index].variationImage2 = files[0];
    } else if (event.target.id === `variationImage3_${index}`) {
      updatedImages[index].variationImage3 = files[0];
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      variations: updatedImages,
    }));
  };

  return (
    <div className="container-fluid px-4">
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="form-group mb-3">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            className="form-control"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="category">Category</label>
          <select
            id="category"
            name="category"
            className="form-control"
            value={formData.category}
            onChange={handleChange}
          >
            <option value="">Select Category</option>
            {parentIds.map((parent) => (
              <option key={parent._id} value={parent.name}>
                {parent.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group mb-3">
          <label htmlFor="subcategory">Subcategory</label>
          <select
            id="subcategory"
            name="subcategory"
            className="form-control"
            value={selectedSubcategory}
            onChange={(e) => setSelectedSubcategory(e.target.value)}
          >
            <option value="">Select Subcategory</option>
            {subcategories.map((subcategory) => (
              <option key={subcategory.name} value={subcategory.name}>
                {subcategory.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group mb-3">
          <label htmlFor="brand">Brand</label>
          <select
            id="brand"
            name="brand"
            className="form-control"
            value={selectedBrand}
            onChange={(e) => setSelectedBrand(e.target.value)}
          >
            <option value="">Select Brand</option>
            {brands.map((brand) => (
              <option key={brand.name} value={brand.name}>
                {brand.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group mb-3">
          <label htmlFor="description">Description</label>
          <textarea
            className="form-control"
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="regularPrice">Regular Price</label>
          <input
            type="text"
            className="form-control"
            id="regularPrice"
            name="regularPrice"
            value={formData.regularPrice}
            onChange={handleChange}
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="salePrice">Sale Price</label>
          <input
            type="text"
            className="form-control"
            id="salePrice"
            name="salePrice"
            value={formData.salePrice}
            onChange={handleChange}
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="stock">Stock</label>
          <input
            type="text"
            className="form-control"
            id="stock"
            name="stock"
            value={formData.stock}
            onChange={handleChange}
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="productimage1">Product Image 1</label>
          <input
            type="file"
            className="form-control"
            id="productimage1"
            name="productimage1"
            onChange={handleImageChange}
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="productimage2">Product Image 2</label>
          <input
            type="file"
            className="form-control"
            id="productimage2"
            name="productimage2"
            onChange={handleImageChange}
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="productimage3">Product Image 3</label>
          <input
            type="file"
            className="form-control"
            id="productimage3"
            name="productimage3"
            onChange={handleImageChange}
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="specifications">Specifications</label>
          <div className="d-flex align-items-center mb-2">
            <button
              type="button"
              className="btn btn-primary ms-2"
              onClick={handleAddSpecification}
            >
              Add Specification
            </button>
          </div>
          {specificationEntries?.map((specification, specIndex) => (
            <div key={specIndex} className="mb-2">
              <div className="d-flex mb-2">
                <select
                  className="form-control me-2"
                  value={specification?.key}
                  onChange={(event) =>
                    handleSpecificationChange(specIndex, event)
                  }
                >
                  <option value="">Select Key</option>
                  {specifications.map((specKey) => (
                    <option key={specKey} value={specKey}>
                      {specKey}
                    </option>
                  ))}
                </select>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => handleRemoveSpecification(specIndex)}
                >
                  Remove Specification
                </button>
              </div>
              {specification?.values.map((value, valueIndex) => (
                <div key={valueIndex} className="d-flex mb-2">
                  <input
                    type="text"
                    placeholder="Value"
                    className="form-control me-2"
                    value={value}
                    onChange={(event) =>
                      handleValueChange(specIndex, valueIndex, event)
                    }
                    required
                  />
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => handleRemoveValue(specIndex, valueIndex)}
                  >
                    Remove Value
                  </button>
                </div>
              ))}
              <button
                type="button"
                className="btn btn-primary mb-2"
                onClick={() => handleAddValue(specIndex)}
              >
                Add Value
              </button>
            </div>
          ))}
        </div>

        {formData.variations.map((variation, index) => (
          <>
            <form key={index}>
              <div>
                <label>Color:</label>
                <input
                  type="text"
                  name="color"
                  value={variation.color}
                  onChange={(e) =>
                    handleVariationChange(index, "color", e.target.value)
                  }
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="size">Size:</label>
                <input
                  type="text"
                  className="form-control"
                  id="size"
                  name="size"
                  value={variation.size}
                  onChange={(e) =>
                    handleVariationChange(index, "size", e.target.value)
                  }
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="ram">RAM:</label>
                <input
                  type="text"
                  name="ram"
                  value={variation.ram}
                  onChange={(e) =>
                    handleVariationChange(index, "ram", e.target.value)
                  }
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="rom">ROM:</label>
                <input
                  type="text"
                  name="rom"
                  value={variation.rom}
                  onChange={(e) =>
                    handleVariationChange(index, "rom", e.target.value)
                  }
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor={`variationImage1_${index}`}>
                  Product Image 1
                </label>
                <input
                  type="file"
                  className="form-control"
                  id={`variationImage1_${index}`}
                  name={`variationImage1_${index}`}
                  onChange={(e) => handleImageChange1(index, e)}
                  multiple={false} // Remove `multiple` if you only want to upload one image per field
                />
              </div>

              <div className="form-group mb-3">
                <label htmlFor={`variationImage2_${index}`}>
                  Product Image 2
                </label>
                <input
                  type="file"
                  className="form-control"
                  id={`variationImage2_${index}`}
                  name={`variationImage2_${index}`}
                  onChange={(e) => handleImageChange1(index, e)}
                  multiple={false}
                />
              </div>

              <div className="form-group mb-3">
                <label htmlFor={`variationImage3_${index}`}>
                  Product Image 3
                </label>
                <input
                  type="file"
                  className="form-control"
                  id={`variationImage3_${index}`}
                  name={`variationImage3_${index}`}
                  onChange={(e) => handleImageChange1(index, e)}
                  multiple={false}
                />
              </div>
            </form>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => handleRemoveVariation(index)}
            >
              Remove Variation
            </button>
          </>
        ))}

        <div style={{ display: "flex", margin: 10 }}>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => handleVariation()}
          >
            Add Variation
          </button>
        </div>

        <div style={{ display: "flex", margin: 10 }}>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleAddProduct}
          >
            Add Product
          </button>
        </div>
      </form>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Product Added</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your product has been added successfully!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddProduct;
