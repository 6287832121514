import React, { useEffect, useState } from "react";
import { Link ,useNavigate} from "react-router-dom";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import apiConfig from "../../../apiConfig";

const ViewCategory = () => {

  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);
  const [editingCategory, setEditingCategory] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const token = localStorage.getItem('authToken');

  useEffect(() => {
    axios
      .get(`${apiConfig.baseUrl}${apiConfig.getCategory}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      )
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  const handleEdit = (category) => {
    setEditingCategory(category);
    setShowEditModal(true);
  };

console.log(categories)

  const handleUpdate = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.put(
        `${apiConfig.baseUrl}${apiConfig.updateCategory}${editingCategory._id}`,
        {
          name: editingCategory.name,
      
          status: editingCategory.status,
        },
        {
          headers: {
           
            Authorization: `Bearer ${token}`,
          }
        }
      );
      console.log("Category updated:", response.data);
      setCategories(
        categories.map((cat) =>
          cat._id === editingCategory._id ? editingCategory : cat
        )
      );
      setEditingCategory(null);
      setShowEditModal(false);
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `${apiConfig.baseUrl}${apiConfig.deleteCategory}${categoryToDelete._id}`,
        {
          headers: {
           
            Authorization: `Bearer ${token}`,
          }
        }
      );
      setCategories(
        categories.filter((category) => category._id !== categoryToDelete._id)
      );
      setCategoryToDelete(null);
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const openDeleteModal = (category) => {
    setCategoryToDelete(category);
    setShowDeleteModal(true);
  };

  const handleEditCat = (category) => {
    navigate(`/admin/EditCategory/${category._id}`, { state: { category } });
  };



  return (
    <div className="container px-4">
      <div className="card mt-4">
        <div className="card-header">
          <h4>
            Category List
            <Link
              to="/admin/add-Category"
              className="btn btn-primary btn-sm float-end"
            >
              Add Category
            </Link>
          </h4>
        </div>
        <div className="card-body">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th> Category Name</th>
      
                <th>Images</th>
                <th>Status</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {categories.map((category) => (
                <tr key={category._id}>
                  <td>{category._id}</td>
                  <td>{category.name}</td>
          

                  <td>
                    {category.image && category.image.length > 0 ? (
                      <img
                        src={category.image[0]}
                        alt="Category Image"
                        style={{ width: "100px", height: "auto" }}
                      />
                    ) : (
                      <span>No Image</span>
                    )}
                  </td>
                  <td>{category.status ? "Hidden" : "Shown"}</td>
                  <td>
                    <button
                      onClick={() => handleEditCat(category)}
                      className="btn btn-sm btn-warning"
                    >
                      Edit
                    </button>
                  </td>
                  <td>
                    <button
                      onClick={() => openDeleteModal(category)}
                      className="btn btn-sm btn-danger"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>

            <tbody></tbody>
          </table>
        </div>
      </div>

      {/* Edit Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Category</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleUpdate}>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>category Name</Form.Label>
              <Form.Control
                type="text"
                value={editingCategory?.name || ""}
                onChange={(e) =>
                  setEditingCategory({
                    ...editingCategory,
                    name: e.target.value,
                  })
                }
              />
            </Form.Group>
        

            <Form.Group className="mb-3">
              <Form.Label>Slug</Form.Label>
              <Form.Control
                type="text"
                value={editingCategory?.slug || ""}
                onChange={(e) =>
                  setEditingCategory({
                    ...editingCategory,
                    slug: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                label="Status (0=shown, 1=hidden)"
                checked={editingCategory?.status || false}
                onChange={(e) =>
                  setEditingCategory({
                    ...editingCategory,
                    status: e.target.checked,
                  })
                }
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowEditModal(false)}>
              Cancel
            </Button>
            <Button type="submit" variant="primary">
              Update Category
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Delete Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the category "{categoryToDelete?.name}
          "?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewCategory;
