import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import { useParams } from 'react-router-dom';
import logo from '../../../assets/images/icons/logo4.png';
import './ResetPassword.css';
import apiConfig from '../../../apiConfig';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  

  // console.log('Token:', token);
const handleReset = () => {
  
  setPasswordError('');

  if (password.length < 8) {
    setPasswordError('Password must be at least 8 characters long.');
  } else if (password !== confirmPassword) {
    setPasswordError('Passwords do not match.');
  } else {
    const apiUrl = `${apiConfig.baseUrl}${apiConfig.resetPasswordEndpoit}`;
    const requestBody = {
      token: token,
      password: password
    };

    axios.post(apiUrl, requestBody)
      .then(response => {
        console.log('Password reset successful:', response.data);
   
        window.alert('Password reset successful!');
      })
      .catch(error => {
        console.error('Password reset failed:', error);

        setPasswordError('Password reset failed. Please try again later.');
      });


    setPassword('');
    setConfirmPassword('');
  }
};

  
  return (
    <div className="reset-container">
      <img src={logo} style={{ width: '50px', height: '50px' }} alt="logo" />
      <h2 className="reset-heading">Reset Password</h2>
      <div className="reset-form">
        <input
          type="password"
          placeholder="New Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="reset-input"
        />
        <input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          className="reset-input"
        />
        {passwordError && <p className="reset-error">{passwordError}</p>}
        <button onClick={handleReset} className="reset-button">Reset</button>
      </div>
    </div>
  );
};

export default ResetPassword;
