import React, { useState, useEffect } from 'react';
import axios from 'axios';
import apiConfig from "../../../apiConfig";

const Statistics = () => {
  const [stats, setStats] = useState(null); // Initial state to hold statistics data
  const [loading, setLoading] = useState(true); // Initial state for loading status
  const [error, setError] = useState(null); // Initial state for error handling

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const response = await axios.get(`${apiConfig.baseUrl}${apiConfig.Reports}`); // Replace with your API URL
        setStats(response.data);
      } catch (error) {
        console.error('Error fetching statistics:', error);
        setError(`Failed to fetch statistics: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchStatistics();
  }, []); // Empty dependency array to fetch data only once on component mount

  if (loading) {
    return <div>Loading...</div>; // Show a loading message or spinner
  }

  if (error) {
    return <div>Error: {error}</div>; // Show an error message if there's an error
  }

  return (
    <div className="statistics-panel">
      <h1>Admin Panel Statistics</h1>
      <table>
        <thead>
          <tr>
            <th>Metric</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>User Count</td>
            <td>{stats.userCount}</td>
          </tr>
          <tr>
            <td>Total Sales</td>
            <td>{stats.totalSales}</td>
          </tr>
          <tr>
            <td>Order Count</td>
            <td>{stats.orderCount}</td>
          </tr>
          {/* Displaying orderStatusCounts */}
          {Object.entries(stats.orderStatusCounts).map(([status, count]) => (
            <tr key={status}>
              <td>Order Status: {status}</td>
              <td>{count}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Statistics;

