import React, { useState } from 'react';
import axios from 'axios';
import './CreatePromotionalOffers.css';
import apiConfig from '../../../apiConfig';

const CreatePromotionalOffers = () => {
    const [formData, setFormData] = useState({
        title: "",
        description: "",
        discount: "",
        startDate: "",
        endDate: "",
        images: null
    });
      
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setFormData({
            ...formData,
            images: file
        });
    };
  

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const errors = {};
        if (!formData.title) errors.title = 'Title is required';
        if (!formData.description) errors.description = 'Description is required';
        if (!formData.discount) errors.discount = 'Discount is required';
        if (!formData.startDate) errors.startDate = 'Start Date is required';
        if (!formData.endDate) errors.endDate = 'End Date is required';
        if (!formData.images) errors.images = 'Image is required';
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }
    
       
        const formDataToSubmit = new FormData();
        formDataToSubmit.append('title', formData.title);
        formDataToSubmit.append('description', formData.description);
        formDataToSubmit.append('discount', formData.discount);
        formDataToSubmit.append('startDate', formData.startDate);
        formDataToSubmit.append('endDate', formData.endDate);
        formDataToSubmit.append('images', formData.images); 
    
        console.log(formData, "data to submit");
    
        try {
            const response = await axios.post(`${apiConfig.baseUrl}${apiConfig.createOffers}`, formDataToSubmit);
            setSuccessMessage('Offer created successfully');
            console.log('Offer created successfully:', response.data);
            // Reset form data after successful submission
            setFormData({
                title: '',
                description: '',
                discount: '',
                startDate: '',
                endDate: '',
                images: null
            });
        
            setErrors({});
        } catch (error) {
            console.error('Error creating offer:', error);
        }
    };
    

    return (
        <div className="container">
            <h2>Create Promotional Offer</h2>
            {successMessage && <div className="alert alert-success">{successMessage}</div>}
            
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Title:</label>
                    <input type="text" name="title" value={formData.title} onChange={handleChange} className="form-control" required />
                    {errors.title && <div className="text-danger">{errors.title}</div>}
                </div>
                <div className="form-group">
                    <label>Description:</label>
                    <input type="text" name="description" value={formData.description} onChange={handleChange} className="form-control" required />
                    {errors.description && <div className="text-danger">{errors.description}</div>}
                </div>
                <div className="form-group">
                    <label>Discount (%):</label>
                    <input type="number" name="discount" value={formData.discount} onChange={handleChange} className="form-control" required />
                    {errors.discount && <div className="text-danger">{errors.discount}</div>}
                </div>
                <div className="form-group">
                    <label>Start Date:</label>
                    <input type="date" name="startDate" value={formData.startDate} onChange={handleChange} className="form-control" required />
                    {errors.startDate && <div className="text-danger">{errors.startDate}</div>}
                </div>
                <div className="form-group">
                    <label>End Date:</label>
                    <input type="date" name="endDate" value={formData.endDate} onChange={handleChange} className="form-control" required />
                    {errors.endDate && <div className="text-danger">{errors.endDate}</div>}
                </div>
                <div className="form-group">
                    <label>Image:</label>
                    <input type="file" name="images" onChange={handleImageChange}  className="form-control" required />
                    {errors.images && <div className="text-danger">{errors.images}</div>}
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
        </div>
    );
};

export default CreatePromotionalOffers;
