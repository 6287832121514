import React, { useState, useEffect } from 'react';
import axios from 'axios';
import apiConfig from '../../../apiConfig';

const CurrentAppVersion = () => {
  const [latestVersion, setLatestVersion] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchLatestVersion();
  }, []);

  const fetchLatestVersion = async () => {
    try {
      setLoading(true); 
      const response = await axios.get(`${apiConfig.baseUrl}${apiConfig.getversion}`);
      setLatestVersion(response.data.version);
      setLoading(false); 
    } catch (error) {
      setError('Failed to fetch latest version');
      console.error('Failed to fetch latest version:', error);
      setLoading(false); 
    }
  };

  const handleFetchLatestVersion = () => {
    fetchLatestVersion();
  };
 
  
  return (

    <div style={styles.container}>
      <h2>Latest App Version</h2>
      {loading ? (
        <p>Loading...</p>
      ) : latestVersion ? (
        <p style={{ color: 'green' }}>Latest Version: {latestVersion}</p>
      ) : (
        <p>{error}</p>
      )}
      <button style={styles.button} onClick={handleFetchLatestVersion}>Refresh for Latest Version</button>
    </div>
  );
};


const styles = {
  container: {
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    maxWidth: '400px',
    margin: 'auto',
    marginTop: '50px',
    textAlign: 'center',
  },
  button: {
    marginTop: '20px',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    outline: 'none',
  },
};

export default CurrentAppVersion;
