import React, { useEffect, useState } from "react";
import axios from "axios";
import apiConfig from "../../../apiConfig";

const GetAllSpecificationKeys = () => {
  const [specKeys, setSpecKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editKey, setEditKey] = useState(null); // State for editing
  const [editValue, setEditValue] = useState(""); // State for the new value

  useEffect(() => {
    const fetchSpecificationKeys = async () => {
      try {
        const response = await axios.get(
          `${apiConfig.baseUrl}${apiConfig.GetAllspecificationkeys}`
        );

        setSpecKeys(response.data.data || []);
      } catch (error) {
        setError("Failed to fetch specification keys. Please try again later.");
        console.error("Error fetching specification keys:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSpecificationKeys();
  }, []);

  const handleUpdate = async (id) => {
    try {
      const response = await axios.put(
        `${apiConfig.baseUrl}${apiConfig.UpdateSpecifications}${id}`,
        { key: editValue }
      );
      if (response.status === 200) {
        setSpecKeys((prevKeys) =>
          prevKeys.map((key) =>
            key._id === id ? { ...key, key: editValue } : key
          )
        );
        setEditKey(null);
        setEditValue("");
        alert("Specification key updated successfully!"); // Alert message
      }
    } catch (error) {
      console.error("Error updating specification key:", error);
      alert("Failed to update specification key.");
    }
  };

  const handleDelete = async (id) => {
    try {
      //const url = `${apiConfig.baseUrl}${apiConfig.DeleteSpecifications}/${id}`;
      const response = await axios.delete(
         `${apiConfig.baseUrl}${apiConfig.DeleteSpecifications}/${id}`
      );
      if (response.status === 200) {
        setSpecKeys((prevKeys) => prevKeys.filter((key) => key._id !== id));
        alert("Specification key deleted successfully!");
      }
    } catch (error) {
      console.error("Error deleting specification key:", error);
      alert("Failed to delete specification key.");
    }
  };

  if (loading) {
    return <p>Loading specifications...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div
      style={{
        margin: "40px auto",
        maxWidth: "600px",
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#f9f9f9",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <h2 style={{ color: "#333", marginBottom: "20px", textAlign: "center" }}>
        Specification Keys
      </h2>
      <ul
        style={{
          listStyleType: "none",
          padding: "0",
          fontSize: "16px",
          color: "#555",
          borderTop: "1px solid #ddd",
        }}
      >
        {specKeys.length > 0 ? (
          specKeys.map((key) => (
            <li
              key={key._id}
              style={{
                padding: "10px",
                borderBottom: "1px solid #ddd",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {editKey === key._id ? (
                <input
                  type="text"
                  value={editValue}
                  onChange={(e) => setEditValue(e.target.value)}
                  style={{
                    padding: "8px",
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    fontSize: "16px",
                    flex: "1",
                  }}
                />
              ) : (
                <span style={{ flex: "1", marginRight: "10px" }}>
                  {key.key}
                </span>
              )}
              <button
                onClick={() =>
                  editKey === key._id
                    ? handleUpdate(key._id)
                    : (setEditKey(key._id), setEditValue(key.key))
                }
                style={{
                  padding: "8px 12px",
                  backgroundColor: editKey === key._id ? "#28a745" : "#007bff",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                {editKey === key._id ? "Save" : "Edit"}
              </button>
              <button
                onClick={() => handleDelete(key._id)}
                style={{
                  padding: "8px 12px",
                  backgroundColor: "#dc3545",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Delete
              </button>
            </li>
          ))
        ) : (
          <li>No specification keys available.</li>
        )}
      </ul>
    </div>
  );
};

export default GetAllSpecificationKeys;
