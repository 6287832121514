import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import apiConfig from "../../../apiConfig";

const ViewPromotionalOffers = () => {
  const [offers, setOffers] = useState([]);
  const [editOffer, setEditOffer] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    discount: "",
    startDate: "",
    endDate: "",
  });
  const [selectedOfferImage, setSelectedOfferImage] = useState(null); 

  useEffect(() => {
    fetchOffers();
  }, []);

  const fetchOffers = async () => {
    try {
      const response = await axios.get(
       `${apiConfig.baseUrl}${apiConfig.getPromotinalOffers}`
      );
      setOffers(response.data);
    } catch (error) {
      console.error("Error fetching offers:", error);
    }
  };

  const handleEditOffer = (offer) => {
    setEditOffer(offer);
    setShowModal(true);
    setFormData({
      title: offer.title,
      description: offer.description,
      discount: offer.discount,
      startDate: offer.startDate,
      endDate: offer.endDate,
    });
    setSelectedOfferImage(offer.images[0]); 
  };

  const handleDeleteOffer = async (offerId) => {
    try {
      await axios.delete(`${apiConfig.baseUrl}${apiConfig.deleteOffers}${offerId}`);
      fetchOffers();
    } catch (error) {
      console.error("Error deleting offer:", error);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setEditOffer(null);
    setFormData({
      title: "",
      description: "",
      discount: "",
      startDate: "",
      endDate: "",

    });
    setSelectedOfferImage(null); 
  };

  const handleModalSubmit = async () => {
    try {
      await axios.put(
        `${apiConfig.baseUrl}${apiConfig.updatePromotin}${editOffer._id}`,
        formData
      );
      fetchOffers();
      handleModalClose();
    } catch (error) {
      console.error("Error updating offer:", error);
    }
  };

  return (
    <div>
      <h2>View Promotional Offers</h2>
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Discount (%)</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {offers.map((offer) => (
            <tr key={offer._id}>
              <td>{offer.title}</td>
              <td>{offer.description}</td>
              <td>{offer.discount}</td>
              
              <td>{offer.startDate}</td>
              <td>{offer.endDate}</td>
              <td>
                <img
                  src={offer.images[0]}
                  alt="Offer"
                  style={{ width: "100px", height: "auto" }}
                />
              </td>

              <td>
                <button onClick={() => handleEditOffer(offer)}>Edit</button>
                <button onClick={() => handleDeleteOffer(offer._id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showModal && (
        <Modal show={showModal} onHide={handleModalClose}>
          <h2>Edit Offer</h2>
          <form onSubmit={handleModalSubmit}>
            <div>
              <label>Title:</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
                required
              />
            </div>
            <div>
              <label>Description:</label>
              <input
                type="text"
                name="description"
                value={formData.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
                required
              />
            </div>
            <div>
              <label>Discount (%):</label>
              <input
                type="number"
                name="discount"
                value={formData.discount}
                onChange={(e) =>
                  setFormData({ ...formData, discount: e.target.value })
                }
                required
              />
            </div>
            <div>
              <label>Start Date:</label>
              <input
                type="date"
                name="startDate"
                value={formData.startDate}
                onChange={(e) =>
                  setFormData({ ...formData, startDate: e.target.value })
                }
                required
              />
            </div>
            <div>
              <label>End Date:</label>
              <input
                type="date"
                name="endDate"
                value={formData.endDate}
                onChange={(e) =>
                  setFormData({ ...formData, endDate: e.target.value })
                }
                required
              />
            </div>
            {selectedOfferImage && (
              <div>
                <label>Image:</label>
                <img
                  src={selectedOfferImage}
                  alt="Offer"
                  style={{ width: "100px", height: "auto" }}
                />
              </div>
            )}
            <button type="submit">Save</button>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default ViewPromotionalOffers;
